import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import './ScoreInput.css'
import './ScoreAndCheck.css'
import './Scores.css'
import './Game.css'
import Header from '../Header/Header';
import GameState from '../GameState/GameState';
import CheckOuts from '../Micro/CheckOuts';
import Stats from '../Micro/Stats'
import RoundAndScore from '../Micro/RoundAndScore'
import RoundRow from '../Micro/RoundRow';
import Footer from '../Footer/Footer'
import {checkValidShot, checkOuts, updateShotStat, updateAvg, update9Avg, updateRoundList, emptyRoundList, updateBestLegCheckouts, getWindowDimension, remainingScore, undoLastScore} from '../../tools/toolFunctions'
import { shotToDb, matchToDb, getPlayerName} from '../../tools/dbFunctions'
import ActScore from '../Micro/ActScore'
import Keypad from '../Keypad/Keypad';
import ReactModal from 'react-modal'
import { useTranslation } from "react-i18next";
/* import Modal from '../Modal/Modal'; */

export default function GameScreen() {
    const location = useLocation()
    const { t } = useTranslation();
    let API_URL = process.env.REACT_APP_API_URL
    const gameData = location.state.data
    console.log("GAMEDATA Kapta:", gameData)
    const navigate = useNavigate()
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms))
    /* GAME STARTING DATA */
    const { width, height } = getWindowDimension()
    let setnumber = 0
    /* let legnumber = 1 */
    let maxSetNumber = 0
    let maxLegNumber = 0
    const [round, setRound] = useState(1)
    const modRound = (input) => setRound(input)
    if (gameData.sets > 0) {setnumber = 1; maxSetNumber = gameData.sets} else {setnumber = 0; maxSetNumber = 0}
    const [set, setSet] = useState(setnumber)
    if (gameData.legsperset > 0) {maxLegNumber = gameData.legsperset} else {maxLegNumber = 0}
    const [leg, setLeg] = useState(1)
    const [legStart, setLegStart] = useState(1)
    const [setStart, setSetStart] = useState(1)
    const [player1_name, setPlayer1Name] = useState("Player 1")
    const modPlayer1Name = (input) => {setPlayer1Name(input)}
    const [player2_name, setPlayer2Name] = useState("Player 2")
    const modPlayer2Name = (input) => {setPlayer2Name(input)}
    const [active, setActive] = useState(1)
    const getActive = () => {return active}
    const modActive = (input) => {setActive(input)}
    /* CHEKOUTS */
    const [hints1, setHints1] = useState([])
    const [hints2, setHints2] = useState([])
    const modHints1 = (input) => {setHints1(input)}
    const modHints2 = (input) => {setHints2(input)}
    /* STATISTICS */
    const statLabel = [
      {"key": "60", "label": "60+"}, 
      {"key": "80", "label": "80+"}, 
      {"key": "100", "label": "100+"}, 
      {"key": "120", "label": "120+",}, 
      {"key": "140", "label": "140+",}, 
      {"key": "180", "label": "180"}
    ]
    const [stat1Values, setStat1Values] = useState([
        {"value": 0}, 
        {"value": 0}, 
        {"value": 0}, 
        {"value": 0}, 
        {"value": 0}, 
        {"value": 0}
    ])
    const [stat2Values, setStat2Values] = useState([
      {"value": 0}, 
      {"value": 0}, 
      {"value": 0}, 
      {"value": 0}, 
      {"value": 0}, 
      {"value": 0}
    ])
    const modStats1 = (input) => {setStat1Values(input)}
    const getStats1 = () => {return stat1Values} 
    const modStats2 = (input) => {setStat2Values(input)}
    const getStats2 = () => {return stat2Values}
    /* AVERAGES */
    const avgLabel = [
      {"key": "max", "label": t("game_avg_label_max")},
      {"key": "best", "label": t("game_avg_label_best")},
      {"key": "avg", "label": t("game_avg_label_avg")},
      {"key": "first9", "label": t("game_avg_label_9dart")}
    ]
    const [avg1Values, setAvg1Values] = useState([
      {"value": 0}, 
      {"value": 0}, 
      {"value": 0}, 
      {"value": 0}
    ])
    const modAvg1 = (input) => {setAvg1Values(input)}
    const getAvg1 = () => {return avg1Values}
    const [avg2Values, setAvg2Values] = useState([
      {"value": 0}, 
      {"value": 0}, 
      {"value": 0}, 
      {"value": 0}
    ])
    const modAvg2 = (input) => {setAvg2Values(input)}
    const getAvg2 = () => {return avg2Values}    
    const [sum1, setSum1] = useState(0)
    const modSum1 = (input) => {setSum1(input)}
    const getSum1 = () => {return sum1}
    const [sumDart1, setSumDart1] = useState(0)
    const modSumDart1 = (input) => {setSumDart1(input)}
    const getSumDart1 = () => {return sumDart1}
    const [dartCount, setDartCount] = useState(3) /* Hány nyilat dobott - default=3 */
    const [sum2, setSum2] = useState(0)
    const modSum2 = (input) => {setSum2(input)}
    const getSum2 = () => {return sum2}
    const [sumDart2, setSumDart2] = useState(0)
    const modSumDart2 = (input) => {setSumDart2(input)}
    const getSumDart2 = () => {return sumDart2}
    const [sum1_9, setSum1_9] = useState(0)
    const modSum1_9 = (input) => {setSum1_9(input)}
    const getSum1_9 = () => {return sum1_9}
    const [sumDart1_9, setSumDart1_9] = useState(0)
    const modSumDart1_9 = (input) => {setSumDart1_9(input)}
    const getSumDart1_9 = () => {return sumDart1_9}
    const [sum2_9, setSum2_9] = useState(0)
    const modSum2_9 = (input) => {setSum2_9(input)}
    const getSum2_9 = () => {return sum2_9}
    const [sumDart2_9, setSumDart2_9] = useState(0)
    const modSumDart2_9 = (input) => {setSumDart2_9(input)}
    const getSumDart2_9 = () => {return sumDart2_9}
    /* RUNNING */
    const [score, setScore] = useState("")
    const getScore = () => {return score}
    const modScore = (input) => {setScore(input)}
    const [wasShot, setWasShot] = useState(false)
    const modWasShot = (input) => {setWasShot(input)}
    const [score1, setScore1] = useState(Number(gameData.variant) + Number(gameData.hc1))
    const getScore1 = () => {return score1}
    const modScore1 = (input) => {setScore1(input)}
    const [score2, setScore2] = useState(Number(gameData.variant) + Number(gameData.hc2))
    const getScore2 = () => {return score2}
    const modScore2 = (input) => {setScore2(input)}
    const [shot_data1, setShotData1] = useState([])
    const modShotData1 = (input) => {setShotData1(input)}
    const getShotData1 = () => {return shot_data1}
    const [shot_data2, setShotData2] = useState([])
    const modShotData2 = (input) => {setShotData2(input)}
    const getShotData2 = () => {return shot_data2}
    const [roundData, setRoundData] = useState([])
    const getRoundData = () => {return roundData}
    const modRoundData = (input) => {setRoundData(input)}
    const [wonLeg1, setWonLeg1] = useState(0)
    const [wonLeg2, setWonLeg2] = useState(0)
    const [wonSet1, setWonSet1] = useState(0)
    const [wonSet2, setWonSet2] = useState(0)
    /* Modal dialog  */
    const [showModal, setShowModal] = useState(false)
    let check = null
    
    const handleModalClose = (clicked) => {
      if (clicked) {
        navigate(`/`, {replace: true})
      } else {
        setShowModal(false)
      }
    };

    useEffect(() => {  /* Játékosnevek beolvasása, betöltődéskor 1-szer fut le */
      getPlayerName(gameData.player1_id, modPlayer1Name)
      getPlayerName(gameData.player2_id, modPlayer2Name)
    }, [])
    
    useEffect(() => { /* Ha volt dobás, minden dobás után lefut, az ENTER változtatja a STATE-et */
      scoreWrite()
    }, [wasShot])
    
    function scoreWrite() {
      /* var kiszallt = '' */
      if (wasShot) {
        if (active === 1) {   /* Az 1-es az aktiv játékos */
          /* Ellenőrizni kell majd, hogy érvényes-e (nem sokalt be) */
          const validShot = checkValidShot(score, score1)
          if (validShot === 1) {
            updateRoundList(getShotData1, modShotData1, round, score, score1)
            /* if (legStart === 1) {
              const item = {"number": round * 3}
              let round_data = roundData
              round_data.push(item)
              setRoundData(round_data)
            } */
            /* 1-es aktuális pontjának változtatása */
            const newScore = score1 - score
            setScore1(newScore)
            /* Ki kell írni db-be a dobást */
            shotToDb(gameData.player1_id, score,  leg,  set, gameData.id, round * 3)
            /* Van-e kiszálló */
            checkOuts(modHints1, newScore)
            /* Update-elni a dobás statisztikát */
            updateShotStat(getStats1, modStats1 ,score)
            /* Átlagok update-je getSumFn, modSumFn, dartCount, getAvgFn, modAvgFn, score, round_number */
            updateAvg(getSum1, modSum1, getSumDart1, modSumDart1 , dartCount, getAvg1, modAvg1, score, round)
            if (round <= 3) {
              update9Avg(getSum1_9, modSum1_9, getSumDart1_9, modSumDart1_9, getAvg1, modAvg1, score)
              /* updateAvg(getSum1_9, modSum1_9, getSumDart1_9, modSumDart1_9 , 3, getAvg1, modAvg1, score, round) */
            }
            /* Ha nem ő kezdte a leget, akkor növelni a round_number-t */
            if (legStart === 2) {
              const new_round = round + 1
              setRound(new_round)              
            }
            setActive(2)
          } else if (validShot === 0) {  /* 0 vagy besokalt */
            updateRoundList(getShotData1, modShotData1, round,  0, score1)
            /* if (legStart === 1) {
              const item = {"number": round * 3}
              let round_data = roundData
              round_data.push(item)
              setRoundData(round_data)
            } */
            /* Ki kell írni db-be a dobást */
            shotToDb(gameData.player1_id, 0,  leg,  set, gameData.id, round * 3)
            /* Átlagok update-je */
            updateAvg(getSum1, modSum1, getSumDart1, modSumDart1, dartCount, getAvg1, modAvg1, 0, round)
            if (round <= 3) {
              update9Avg(getSum1_9, modSum1_9, getSumDart1_9, modSumDart1_9, getAvg1, modAvg1, 0)
              /* updateAvg(getSum1_9, modSum1_9, getSumDart1_9, modSumDart1_9 , 3, getAvg1, modAvg1, score, round) */
            }
            /* Ha nem ő kezdte a leget, akkor növelni a round_number-t */
            if (legStart === 2) {
              const new_round = round + 1
              setRound(new_round)              
            }
            setActive(2)
          } else if (validShot === 2) {    /* Kiszállt */
            check = Number(prompt("Hány nyílból szált ki?"))
            while (check<1 || check>3) {
              check = Number(prompt("Hány nyílból szált ki?"))
            }
            console.log("check a scorewrite-ban:", check)
            setScore1(Number(gameData.variant) + Number(gameData.hc1))
            setScore2(Number(gameData.variant) + Number(gameData.hc2))
            emptyRoundList(modShotData1)
            emptyRoundList(modShotData2)
            setRoundData([])
            checkOuts(modHints1, 0)
            checkOuts(modHints2, 0)
            shotToDb(gameData.player1_id, score,  leg,  set, gameData.id, (Number(round)-1)*3+Number(check))
            updateAvg(getSum1, modSum1, getSumDart1, modSumDart1, check, getAvg1, modAvg1, score, round)
            if (round <= 3) {
              update9Avg(getSum1_9, modSum1_9, getSumDart1_9, modSumDart1_9, getAvg1, modAvg1, score)
              /* updateAvg(getSum1_9, modSum1_9, getSumDart1_9, modSumDart1_9 , 3, getAvg1, modAvg1, score, round) */
            }
            updateBestLegCheckouts(round, getAvg1, modAvg1, check, score)
            matchToDb(gameData.id, leg, set, gameData.player1_id)
            setRound(1)
            check = 0
            const prev_won_leg = wonLeg1
            setWonLeg1(prev_won_leg + 1)   /* Növeljük a nyert leg-ek számát */
            const prev_leg = leg          /*  kiolvassuk a játszott leg-ek számát */
            /* Itt kell ellenőrizni, hogy SET-re, illetve max LEG-re játszák-e, és ezt az alapján kell futtatni */
            if ((maxLegNumber === 0 || maxLegNumber > prev_won_leg + 1) && (maxSetNumber === 0)) {   /* A meccsben  még kell játszani (nincs SET) */
              console.log("Nics set és van még leg:", )
              setLeg(prev_leg + 1)
              if (legStart === 1) {
                setLegStart(2)
                setActive(2)
              } else {
                setLegStart(1)
                setActive(1)
              }
            }   else {  /* Nem set-re játszuk, játék vége */
                  if (maxSetNumber === 0) {
                    alert("VÉGE SET nélküli")
                  }                  
                } 
            /* elértük a meccsben, vagy set-ben beállított legszámot */
              if (maxSetNumber > 0) { /* set-re játszuk */
                console.log("SET-re játszuk")

                if ((prev_won_leg + 1) < maxLegNumber) {
                  /* const prev_leg = leg */
                  setLeg(prev_leg + 1)
                  if (legStart === 1) {
                    setLegStart(2)
                    setActive(2)
                  } else {
                    setLegStart(1)
                    setActive(1)
                  }
                } else {  /* Ha elértük a SET-enkénti leg számot */
                  const prev_set = set
                  setSet(prev_set + 1) /* Megnöveljük a nyert SET-ek számát */
                  
                  setWonLeg1(0)
                  setWonLeg2(0)
                  const prev_won_set = wonSet1
                  setWonSet1(prev_won_set + 1)
                  setLeg(1)
                  if (maxSetNumber > prev_won_set + 1) {  /* A meccsben még kell SET-et játszani */
                    if (setStart === 1) {
                        setSetStart(2)
                        setLegStart(2)
                        setActive(2)
                    } else {
                      setSetStart(1)
                      setLegStart(1)
                      setActive(1)
                    }
                  } else {  /* Nem set-re játszuk, játék vége */
                      alert("VÉGE SET-es")
                    }
                  }
                }   
            
            
            
            
            
          }
        } 
        else {  /* Itt meg active == 2, ugyanaz kell mint fenn, csak a 2.es játékosra */
          const validShot = checkValidShot(score, score2)
          if (validShot === 1) {
            updateRoundList(getShotData2, modShotData2, round, score, score2)
            /* if (legStart === 2) {
              const item = {"number": round * 3}
              let round_data = roundData
              round_data.push(item)
              setRoundData(round_data)
            } */
            shotToDb(gameData.player2_id, score,  leg,  set, gameData.id, round * 3)
            updateShotStat(getStats2, modStats2 ,score)
            updateAvg(getSum2, modSum2, getSumDart2, modSumDart2, dartCount, getAvg2, modAvg2, score, round)
            if (round <= 3) {
              update9Avg(getSum2_9, modSum2_9, getSumDart2_9, modSumDart2_9, getAvg2, modAvg2, score)
              /* updateAvg(getSum2_9, modSum2_9, getSumDart2_9, modSumDart2_9 , 3, getAvg2, modAvg2, score, round) */
            }
            const newScore = score2 - score
            setScore2(newScore)
            checkOuts(modHints2, newScore)
            if (legStart === 1) {
              const new_round = round + 1
              setRound(new_round)              
            }
            setActive(1)
          }  else if (validShot === 0) {       /* 0 vagy besokalt */
            updateRoundList(getShotData2, modShotData2, round, 0, score2)
            /* if (legStart === 2) {
              const item = {"number": round * 3}
              let round_data = roundData
              round_data.push(item)
              setRoundData(round_data)
            } */
            shotToDb(gameData.player2_id, 0,  leg,  set, gameData.id, round * 3)
            updateAvg(getSum2, modSum2, getSumDart2, modSumDart2, dartCount, getAvg2, modAvg2, 0, round)
            if (round <= 3) {
              update9Avg(getSum2_9, modSum2_9, getSumDart2_9, modSumDart2_9, getAvg2, modAvg2, 0)
              /* updateAvg(getSum2_9, modSum2_9, getSumDart2_9, modSumDart2_9 , 3, getAvg2, modAvg2, score, round) */
            }
            if (legStart === 1) {
              const new_round = round + 1
              setRound(new_round)              
            }
            setActive(1)
          } else if (validShot === 2) {        /* Kiszállt */
            check = Number(prompt("Hány nyílból szált ki?"))
            while (isNaN(check) || (check<1 || check>3)) {
              check = Number(prompt("Hány nyílból szált ki?"))
            }
            console.log("check a scorewrite-ban:", check)
            setScore1(Number(gameData.variant) + Number(gameData.hc1))
            setScore2(Number(gameData.variant) + Number(gameData.hc2))
            emptyRoundList(modShotData2)
            emptyRoundList(modShotData1)
            setRoundData([])
            checkOuts(modHints2, 0)
            checkOuts(modHints1, 0)
            shotToDb(gameData.player2_id, score,  leg,  set, gameData.id, (Number(round)-1)*3+Number(check))
            updateAvg(getSum2, modSum2, getSumDart2, modSumDart2, check, getAvg2, modAvg2, score, round)
            if (round <= 3) {
              update9Avg(getSum2_9, modSum2_9, getSumDart2_9, modSumDart2_9, getAvg2, modAvg2, score)
              /* updateAvg(getSum2_9, modSum2_9, getSumDart2_9, modSumDart2_9 , 3, getAvg2, modAvg2, score, round) */
            }
            updateBestLegCheckouts(round, getAvg2, modAvg2, check, score)
            matchToDb(gameData.id, leg, set, gameData.player2_id)
            setRound(1)
            check = 0
            const prev_won_leg = wonLeg2
            setWonLeg2(prev_won_leg + 1)
            const prev_leg = leg

            /* Itt kell ellenőrizni, hogy SET-re, illetve max LEG-re játszák-e, és ezt az alapján kell futtatni */
            if ((maxLegNumber === 0 || maxLegNumber > prev_won_leg + 1) && (maxSetNumber === 0)) {   /* A meccsben  még kell játszani (nincs SET) */
              console.log("Nics set és van még leg:", )
              /* const akt_leg = leg */
              setLeg(prev_leg + 1)
              if (legStart === 2) {
                setLegStart(1)
                setActive(1)
              } else {
                setLegStart(2)
                setActive(2)
              }
            }   else {  /* Nem set-re játszuk, játék vége */
                  if (maxSetNumber === 0) {
                    alert("VÉGE SET nélküli")
                  }                  
                } 
            /* elértük a meccsben, vagy set-ben beállított legszámot */
              if (maxSetNumber > 0) { /* set-re játszuk */
                console.log("SET-re játszuk")
                if ((prev_won_leg + 1) < maxLegNumber) {
                  /* const akt_leg = leg */
                  setLeg(prev_leg + 1)
                  if (legStart === 1) {
                    setLegStart(2)
                    setActive(2)
                  } else {
                    setLegStart(1)
                    setActive(1)
                  }
                } else {
                  const prev_set = set
                  setSet(prev_set + 1)
                  setWonLeg1(0)
                  setWonLeg2(0)
                  const prev_won_set = wonSet2
                  setWonSet2(prev_won_set + 1)
                  setLeg(1)
                  if (maxSetNumber > prev_won_set + 1) {  /* A meccsben még kell SET-et játszani */
                    if (setStart === 1) {
                        setSetStart(2)
                        setLegStart(2)
                        setActive(2)
                    } else {
                      setSetStart(1)
                      setLegStart(1)
                      setActive(1)
                    }
                  } else {  /* Nem set-re játszuk, játék vége */
                      alert("VÉGE SET-es")
                    }
                  }
                }

          }
        }
        /* Dobés elintézve: pontszám nullázás, voltdobás hamis */
        setScore("")
        setWasShot(false)
        /* delay(300) */
        document.getElementById("scoreinput").disabled = false
        document.getElementById("scoreinput").focus()
      }
    }
    const handleScoreChange = event => {
      /* isNaN: nem teljes szám */
      if (!isNaN(event.target.value)) {
        if (event.target.value <= 180) {
          const score = Number(event.target.value)
          setScore(score)
        }
      } else {
        const prevScore = score
        setScore(prevScore)
      }
    }

    const handleScoreSubmit = event => {
      /* Itt le kellene tiltani a bevitelt, amíg ninc kiírva, utána visszaengedélyezni */
      event.preventDefault()
      document.getElementById("scoreinput").disabled = true
      if (score === "") {
        setScore(0)
      }
      setWasShot(true)
    }
    
    function handleKeyPress(e) {
      /* e.preventDefault() */
      if (e.ctrlKey && e.key === "b") {
          console.log("Visszavon")
          /*             
          - shot_dataX-ből utolsó item törlése
          - setActive átállítása
          - ha kell Round csökkentése (attól függ, hogy az adott leg-ben 1. vagy 2. dobó volt)
          - törölt pontszám hozzáadása az aktuális pontszámhoz
          - dobás-statisztika update
          - Átlagok módosítása (sima, 9 nyilas)
          - !!!!! set, leg ellenőrzése, módosítása
          - !!!!! Best leg, chekout ellenőrzése, módosítása, ha leg, vagy set végén volt visszavonás
          - db-ből is törölni a dobást
          - !!!! db-ből is törölni a metch-et, ha szükséges
          */
          undoLastScore(
            getActive, 
            modActive, 
            getShotData1,
            /* modShotData1,  */
            getShotData2,
            /* modShotData2,  */
            getScore1, 
            modScore1, 
            getScore2, 
            modScore2,
            modHints1,
            modHints2,
            gameData.id, /* gameData.id */
            getStats1,
            modStats1,
            getStats2,
            modStats2,
            getSum1,
            modSum1,
            getSum2,
            modSum2,
            getSumDart1,
            modSumDart1,
            getSumDart2,
            modSumDart2,
            dartCount,
            getAvg1,
            modAvg1,
            getAvg2, 
            modAvg2,
            round,
            legStart,
            getSum1_9,
            modSum1_9,
            getSum2_9,
            modSum2_9,
            getSumDart1_9,
            modSumDart1_9,
            getSumDart2_9,
            modSumDart2_9,
            modRound,
            /* getRoundData,
            modRoundData, */
            )                  
      }
      /* if ((e.key === "ArrowRight" || e.key === "ArrowLeft") && set === 0 && leg === 1) { */
      if (e.key === "ArrowRight"  && set <= 1 && leg === 1 && round === 1 && active === legStart) {
        if (legStart === 1) {
          setLegStart(2)
          setSetStart(2)
          setActive(2)
        } else {
          setLegStart(1)
          setSetStart(1)
          setActive(1)
        }
      }
      switch (e.key) {
        case "F1":
          e.preventDefault()
          setScore(0)
          setWasShot(true)
          break
        case "F2":
          e.preventDefault()
          setScore(26)
          setWasShot(true)
          /* scoreWrite() */
          break
        case "F3":
          e.preventDefault()
          setScore(41)
          setWasShot(true)
          /* scoreWrite() */
          break
        case "F4":
          e.preventDefault()
          setScore(45)
          setWasShot(true)
          /* scoreWrite() */
          break
        case "F5":
          e.preventDefault()
          setScore(60)
          setWasShot(true)
          break
        case "F6":
          e.preventDefault()
        setScore(81)
        setWasShot(true)
        break
        case "F7":
          e.preventDefault()
          setScore(85)
          setWasShot(true)
          break
        case "F8":
          e.preventDefault()
          setScore(100)
          setWasShot(true)
          break
        case "F9":
          e.preventDefault()
          remainingScore(modScore, modWasShot, active, score1, score2, getScore)
          break
        case "F10":
        e.preventDefault()
        setShowModal(true)
        // Itt csak ennyi. Majd a Modal válasznál kezeljük le - (handleModalClose)
        /* navigate(`/`, {replace: true}) */
        break
      }
    }

    return (    
    <div className='main_page'>
      <div> 
        <Header name1={player1_name} name2={player2_name} active={active} leg1={wonLeg1} leg2={wonLeg2} set1={wonSet1} set2={wonSet2} width={width}/>
        <GameState score1={score1} score2={score2} active={active} leg1={wonLeg1} leg2={wonLeg2} set1={wonSet1} set2={wonSet2} width={width}/>        
        <div className='scores'>
        { width >= 600 ? <ActScore remainingScore={score1} active={active === 1 ? '1':'0'} /> : ""}
          <div className='score_check_container'>
              <CheckOuts hints={hints1} />
              <div className='input_field_container'>                
                  <form onSubmit={handleScoreSubmit}>
                  <input
                    type='text'
                    id='scoreinput'
                    value={score}
                    autoFocus
                    onChange={handleScoreChange}
                    onKeyDown={(e) => handleKeyPress(e)}                  
                  />
                </form>                                         
              </div>
              <CheckOuts hints={hints2} />
          </div>
          { width >= 600 ? <ActScore remainingScore={score2} active={active === 2 ? '1':'0'} /> : ""}
        </div>
        <div className='statistics_container'>
          <div className='player1_stat'>
            <div className='left_stat_container'>
                <Stats stat_label={statLabel} stat_values={stat1Values}/>
                <div className='stat_hr'>
                  <hr />
                </div>              
                <Stats stat_label={avgLabel} stat_values={avg1Values} />
            </div>
            <RoundAndScore shots_data1={shot_data1} shots_data2={shot_data2} round={round} side="left"/>
          </div>
          {/* <div className='rounds-row'>
            <RoundRow rounds_data={roundData}/>
          </div> */}
          <div className='player2_stat'>
            <RoundAndScore shots_data1={shot_data1} shots_data2={shot_data2} round={round} side="right"/>                
            <div className='right_stat_container'>
                <Stats stat_label={statLabel} stat_values={stat2Values}/>
                <div className='stat_hr'>
                  <hr />
                </div>              
                <Stats stat_label={avgLabel} stat_values={avg2Values} />
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={showModal}
        contentLabel='Minimal'
        className='Modal-dialog'
        overlayClassName='Overlay'
        >
        <div className='modal-content'>
          <p className='warning-title'>{t("game_exitmodal_warning_title")}</p>
          <div className='warning-text'>
            <p>{t("game_exitmodal_warning_text")}</p>
          </div>
          <div className='modal-buttons'>
            <button onClick={() => handleModalClose(0)}>{t("game_exitmodal_no_button_label")}</button>
            <button autoFocus onClick={() => handleModalClose(1)}>{t("game_exitmodal_yes_button_label")}</button>
          </div>
        </div>
      </ReactModal>
      <Footer />
      { width <= 600 ? <Keypad 
                          getActive={getActive}
                          modActive={modActive}
                          getShotData1={getShotData1}
                          getShotData2={getShotData2}
                          getScore1={getScore1} 
                          modScore1={modScore1}
                          getScore2={getScore2}
                          modScore2={modScore2}
                          modScore={modScore} 
                          modWasShot={modWasShot} 
                          getScore={getScore} 
                          modHints1={modHints1}
                          modHints2={modHints2}
                          gameDataId={gameData.id}
                          getStats1={getStats1}
                          modStats1={modStats1}
                          getStats2={getStats2}
                          modStats2={modStats2}
                          getSum1={getSum1}
                          modSum1={modSum1}
                          getSum2={getSum2}
                          modSum2={modSum2}
                          getSumDart1={getSumDart1}
                          modSumDart1={modSumDart1}
                          getSumDart2={getSumDart2}
                          modSumDart2={modSumDart2}
                          dartCount={dartCount}
                          getAvg1={getAvg1}
                          modAvg1={modAvg1}
                          getAvg2={getAvg2}
                          modAvg2={modAvg2}
                          round={round}
                          legStart={legStart}
                          getSum1_9 = {getSum1_9}
                          modSum1_9 = {modSum1_9}
                          getSum2_9 = {getSum2_9}
                          modSum2_9 = {modSum2_9}
                          getSumDart1_9 = {getSumDart1_9}
                          modSumDart1_9 = {modSumDart1_9}
                          getSumDart2_9 = {getSumDart2_9}
                          modSumDart2_9 = {modSumDart2_9}
                          modRound = {modRound}
                          getRoundData = {getRoundData}
                          modRoundData = {modRoundData}
                        /> 
                        : 
                        ""
      }
    </div>
  )
}
