import React, {useEffect, useState} from 'react'
import axios from 'axios'
import "./MatchStatus.css"
import { use } from 'i18next'

function MatchStatus({selectedMatch, matchShoots, matchsettings, player1Name, player2Name}) {
    let API_URL = process.env.REACT_APP_API_URL
    const matchSettings = matchsettings
    const [resultPerSet, setResultPerSet] = useState(Object)                                   // set-enként nyert legek
    const [winnedSets, setWinnedSets] = useState(Object)                                   // nyert szetek száma
    const [sumPointsPlayer1, setSumPointsPlayer1] = useState(null)
    const [sumPointsPlayer2, setSumPointsPlayer2] = useState(null)
    const [sumDartCountPlayer1, setSumDartCountPlayer1] = useState(null)
    const [sumDartCountPlayer2, setSumDartCountPlayer2] = useState(null)
    const [sumFirst9PointsPlayer1, setSumFirst9PointsPlayer1] = useState(null)
    const [sumFirst9PointsPlayer2, setSumFirst9PointsPlayer2] = useState(null)
    const [sumFirst9DartCount, setSumFirst9DartCount] = useState(null)
    const [shotsRange1, setShotsRange1] = useState(null)
    const [shotsRange2, setShotsRange2] = useState(null)

    // Ha változik a kiválasztoot meccs, akkor lekérjük az eredményeket
    useEffect(() => {
        if (selectedMatch > 0) {
            getMatchDetail(selectedMatch)
        }        
    }, [selectedMatch])

    useEffect(() => {
        if (selectedMatch > 0 && matchShoots) {
            computeAverages(matchShoots)     
            compteShotsRange(matchShoots)       
        }        
    }, [matchShoots])

    // itt van a kiválasztott meccs leg, set, winner adatai
    const getMatchDetail = async (matchId) => {
        await axios({
            method: "GET",
            url: `${API_URL}/matches_owner/${matchId}/`,
        }).then((response) => {
            const tempData = response.data
            /* setMatchDetail(tempData)  */
            computeResult(tempData)
            console.log("match detail: ", tempData)
        })
    }

    function compteShotsRange(data ) {
        const pointRanges = [180, 0, 40, 60, 80, 100, 120, 140, 180];
        
        const countPointsByRange = (playerId) => {
            const pointsCount = {};

            pointRanges.forEach((range, index) => {
                pointsCount[`range${index}`] = 0;
            });

            data.forEach((row) => {
                if (row.player_id === playerId) {
                pointRanges.forEach((range, index) => {
                    if (index <= 1 && row.points === range) { pointsCount[`range${index}`]++; }
                    if  (index > 1 && (row.points >= pointRanges[index -1] && row.points < range))
                    { pointsCount[`range${index}`]++; }
                });
                }
            });

            return pointsCount;
        };

        const player1PointsCount = countPointsByRange(matchsettings.player1_id);
        const player2PointsCount = countPointsByRange(matchsettings.player2_id);
        setShotsRange1(player1PointsCount)
        setShotsRange2(player2PointsCount)
        console.log("p1 shot range: ", player1PointsCount)
        console.log("p2 shot range: ", player2PointsCount)
    }

    function computeAverages(data) {
        // Összegzi a player_id alapján a points értékeit
        const sumPointsByPlayer = data.reduce((acc, row) => {
            const { player_id, points } = row;
            acc[player_id] = (acc[player_id] || 0) + points;
            return acc;
        }, {});
        setSumPointsPlayer1(sumPointsByPlayer[matchsettings.player1_id])
        setSumPointsPlayer2(sumPointsByPlayer[matchsettings.player2_id])
        console.log("Összegzett points player_id alapján (sumPointsByPlayer):", sumPointsByPlayer);

        const sum9PointsByPlayer = data.reduce((result, row) => {
            const { player_id, round_number, points } = row;
            if (round_number <= 9) {
              if (!result[player_id]) {
                result[player_id] = 0;
              }
              result[player_id] += points;
            }
            return result;
          }, {});
          setSumFirst9PointsPlayer1(sum9PointsByPlayer[matchsettings.player1_id])
          setSumFirst9PointsPlayer2(sum9PointsByPlayer[matchsettings.player2_id])
          console.log("9 nyilas összeg (sum9PointsByPlayer):", sum9PointsByPlayer);

        const uniquePairs = new Set();
        data.forEach(row => {
            const { set_id, leg_id } = row;
            const pair = `${set_id}-${leg_id}`;
            uniquePairs.add(pair);
          });
        const totalFirst9DartCount = uniquePairs.size * 3;
        setSumFirst9DartCount(totalFirst9DartCount)
        console.log("Első 9 nyíl darabszám (totalFirst9DartCount):",totalFirst9DartCount);
          

        const sumMaxRoundNumberByPlayer = {};
        data.forEach((row) => {
            const { player_id, set_id, leg_id, round_number } = row;
            const key = `${player_id}-${set_id}-${leg_id}`;
            if (!sumMaxRoundNumberByPlayer[player_id]) {
                sumMaxRoundNumberByPlayer[player_id] = 0;
            }
            if (!sumMaxRoundNumberByPlayer[key] || sumMaxRoundNumberByPlayer[key] < round_number) {
                sumMaxRoundNumberByPlayer[key] = round_number;
            }
        });

        // Összegzés a player_id alapján
        const sumByPlayer = {};
        Object.keys(sumMaxRoundNumberByPlayer).forEach((key) => {
            const playerId = key.split('-')[0];
            if (!sumByPlayer[playerId]) {
                sumByPlayer[playerId] = 0;
            }
            sumByPlayer[playerId] += sumMaxRoundNumberByPlayer[key];
        });
        setSumDartCountPlayer1(sumByPlayer[matchsettings.player1_id])
        setSumDartCountPlayer2(sumByPlayer[matchsettings.player2_id])
        console.log("Összegzett max round_number player_id alapján (sumByPlayer):", sumByPlayer);
    }

    function computeResult(match_detail) {
        //matchsettings.player1_id
        const osszesites = {};
        match_detail && match_detail.forEach((eredmeny) => {
            const { set_id, winner_id } = eredmeny;
            // Set hozzáadása az összesítéshez, ha még nincs
            if (!osszesites[set_id]) {
            osszesites[set_id] = { nyertesek: {} };
            }
            // Győztes hozzáadása az adott set-hez
            if (!osszesites[set_id].nyertesek[winner_id]) {
            osszesites[set_id].nyertesek[winner_id] = 1;
            } else {
            osszesites[set_id].nyertesek[winner_id]++;
            }
            // Ha valaki nem nyert az adott setben, akkor 0-hoz adja hozzá
            const osszesJatekos = Array.from(new Set(match_detail.map(e => e.winner_id)));
            osszesJatekos.forEach(jatekos => {
            if (!osszesites[set_id].nyertesek[jatekos]) {
                osszesites[set_id].nyertesek[jatekos] = 0;
            }
            });
        });
  
        const gyoztesekSetekSzama = {};
        Object.keys(osszesites).forEach((set_id) => {
            const nyertesek = osszesites[set_id].nyertesek;  //nyertesek: {59: 1, 60: 2}
            // Setben legtöbbet nyert játékos kiválasztása
            const legtobbetNyertJatekos = Object.keys(nyertesek).reduce((a, b) => nyertesek[a] > nyertesek[b] ? a : b);
            // Győztesek számának növelése a legtöbbet nyert játékosnál
            if (!gyoztesekSetekSzama[legtobbetNyertJatekos]) {
                gyoztesekSetekSzama[legtobbetNyertJatekos] = 0;
            }
                gyoztesekSetekSzama[legtobbetNyertJatekos]++;
            });
        // Eredmények kiírása
        setWinnedSets(gyoztesekSetekSzama)
        setResultPerSet(osszesites)
    }

  return (
    <>
    <div className='result-title-container'>
        Beállítások és eredmény:
    </div>
    <div className='settings-container'>
        <div className='variant-container'>
            Variant: {matchsettings && matchsettings.variant}
        </div>
        {matchsettings && matchsettings.hc11 > 0 ? (<div className='hc1-container'>Handicap_1:{matchsettings && matchsettings.hc11}</div>) : ""}
        {matchsettings && matchsettings.hc2 > 0 ? (<div className='hc2-container'>Handicap_2:{matchsettings && matchsettings.hc2}</div>) : ""}
        {matchsettings && matchsettings.sets > 0 ? (<div className='sets-container'>Sets: {matchsettings && matchsettings.sets}</div>) : ""}
        {matchsettings && matchsettings.legsperset > 0 ? (<div className='legsperset-container'>Legs: {matchsettings && matchsettings.legsperset}</div>) : ""}
    </div>
    <div className='names-container'>
        <div className='name1'>
            {player1Name.player_name}
        </div>
        <div className='result-container'>
            {winnedSets  && matchsettings && matchsettings.sets > 0 ? 
                (winnedSets[matchsettings.player1_id] + " : " + winnedSets[matchsettings.player2_id] ) 
                : null}
            {resultPerSet && matchsettings && matchsettings.sets === 0 && '0' in resultPerSet ?  
                (
                    
                    (resultPerSet[0].nyertesek[matchsettings.player1_id] || 0)
                    
                    + " - " + 
                    (resultPerSet[0].nyertesek[matchsettings.player2_id] || 0)
                    
                ) 
                : null}
        </div>
        <div className='name2'>
            {player2Name.player_name}
        </div>
        
    </div>
    <div className='details'>
        {'1' in resultPerSet  ? (<b>A Set-enkénti eredmények:</b>) : null}
        {/* {console.log("Object.keys(object) eredménye: ", Object.keys(resultPerSet))} */}
        <div className='result-details-container'>
        {resultPerSet && matchsettings && matchsettings.sets > 0 && '1' in resultPerSet  ?  
            (
                
                Object.keys(resultPerSet).map((key) => {
                    const values = resultPerSet[key];
                    /* console.log(`Key: ${key}, Values:`, values['nyertesek'][matchsettings.player1_id], values['nyertesek'][matchsettings.player2_id]); */
                    return (
                      <div className='detail' key={key}>
                        {key} : {values['nyertesek'][matchsettings.player1_id]} - {values['nyertesek'][matchsettings.player2_id]}
                      </div>
                    );
                  })
            )
                : null}
        </div>
    </div>
    <div className='stat-container'>
        <div className='stat-title'>
            Statisztika
        </div>
        <div className='averages-container'>
            <div className='p1-stats'>
                  {sumPointsPlayer1 && sumDartCountPlayer1 && sumFirst9PointsPlayer1 && sumFirst9DartCount ? 
                    (<>
                        <div className='p1-stats-row'>
                            <div>3 nyilas átlag:</div><div> {(Number(sumPointsPlayer1) / Number(sumDartCountPlayer1) * 3).toFixed(2)}</div>
                        </div>  
                        <div className='p1-stats-row'>
                            <div>Első 9 nyílas átlag:</div><div> {(Number(sumFirst9PointsPlayer1) / Number(sumFirst9DartCount)).toFixed(2)}</div>
                        </div>
                     </>) : null}
            </div>
            <div className='p2-stats'>
            {sumPointsPlayer2 && sumDartCountPlayer2 && sumFirst9PointsPlayer2 && sumFirst9DartCount ? 
                    (<>
                        <div className='p2-stats-row'>
                            <div>3 nyilas átlag:</div><div> {(Number(sumPointsPlayer2) / Number(sumDartCountPlayer2) * 3).toFixed(2)}</div>
                        </div>
                        <div className='p1-stats-row'>
                            <div>Első 9 nyílas átlag:</div><div> {(Number(sumFirst9PointsPlayer2) / Number(sumFirst9DartCount)).toFixed(2)}</div>
                        </div>
                     </>) : null}
            </div>
            
        </div>
        <div className='shots-range-container'>
            <div className='p1-shots-range'>
                {shotsRange1 ? (<>
                    <div className='p1-range-row'><div>40-</div><div> {shotsRange1["range2"]}</div></div>
                    <div className='p1-range-row'><div>40+</div><div> {shotsRange1["range3"]}</div></div>
                    <div className='p1-range-row'><div>60+ </div><div>{shotsRange1["range4"]}</div></div>
                    <div className='p1-range-row'><div>80+ </div><div>{shotsRange1["range5"]}</div></div>
                    <div className='p1-range-row'><div>100+</div><div> {shotsRange1["range6"]}</div></div>
                    <div className='p1-range-row'><div>120+ </div><div>{shotsRange1["range7"]}</div></div>
                    <div className='p1-range-row'><div>140+</div><div> {shotsRange1["range8"]}</div></div>
                    <div className='p1-range-row'><div>180 </div><div>{shotsRange1["range0"]}</div></div>
                </>) : null}
            </div>
            <div className='p2-shots-range'>
                {shotsRange2 ? (<>
                    <div className='p2-range-row'><div>40-</div><div> {shotsRange2["range2"]}</div></div>
                    <div className='p2-range-row'><div>40+</div><div> {shotsRange2["range3"]}</div></div>
                    <div className='p2-range-row'><div>60+ </div><div>{shotsRange2["range4"]}</div></div>
                    <div className='p2-range-row'><div>80+ </div><div>{shotsRange2["range5"]}</div></div>
                    <div className='p2-range-row'><div>100+</div><div> {shotsRange2["range6"]}</div></div>
                    <div className='p2-range-row'><div>120+ </div><div>{shotsRange2["range7"]}</div></div>
                    <div className='p2-range-row'><div>140+</div><div> {shotsRange2["range8"]}</div></div>
                    <div className='p2-range-row'><div>180 </div><div>{shotsRange2["range0"]}</div></div>
                </>) : null}
            </div>
        </div>
    </div>
    
    </>
  )
}

export default MatchStatus
