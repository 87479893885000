import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect, createContext ,useContext, Fragment } from 'react';
import '../css/App.css';
import MatchSettingsForm from "./MatchSettings/MatchSettings";
import GameScreen from "./Game/Game";
import MainHeader from "./MainHeader/MainHeader";
import Home from "./Home/Home";
/* import RegistrationForm from "./RegisterLogin/RegistrationForm"; */
import UserRegistration from "./RegisterLogin/UserRegistration";
import VerificationError from "./RegisterLogin/VerificationError"
import VerificationSuccess from "./RegisterLogin/VerificationSuccess";
import EmailVerificationSuccess from "./RegisterLogin/EmailverificationSucces";
import UserLogin from "./RegisterLogin/UserLogin";
import Logout from "./RegisterLogin/Logout";
import UserProfil from "./UserProfil/UserProfile";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import RegistrationSuccess from "./RegisterLogin/RegistrationSuccess";
import MatchResults from "./MatchResults/MatchResults";
import PlayersResults from "./PlayersResults/PlayersResults";


export const LoggedContext = createContext(null)

function App() {
    const [loggedUserId, setLoggedUserId] = useState(null)
    const modLoggedUserId = (input) => {setLoggedUserId(input)}
    const [loggedUserName, setLoggedUserName] =useState("")
    const modLoggedUserName = (input) => {setLoggedUserName(input)}
    const [loginTime, setLoginTime] = useState()
    
    useEffect(() => {
        
            setLoggedUserId(window.localStorage.getItem('loggedUserId'))
            setLoggedUserName(window.localStorage.getItem('loggedUserName'))
            setLoginTime(window.localStorage.getItem('login_time'))
    }, [])

    return (
        <BrowserRouter>
            <LoggedContext.Provider value={{
                loggedUserId, 
                setLoggedUserId,
                loggedUserName, 
                setLoggedUserName
                }}>
                <MainHeader loggedUserId={loggedUserId} loggedUserName={loggedUserName}/>
                <Routes>
                    <Route path="/game/" element={<GameScreen />}/>
                    <Route path="/newgamesettings/" element={<MatchSettingsForm  loggedUserId={loggedUserId} loggedUserName={loggedUserName}/>}/>
                    <Route path="/matchresults/" element={<MatchResults loggedUserId={loggedUserId}/>} />
                    <Route path="/playerresults/" element={<PlayersResults loggedUserId={loggedUserId}/>} />
                    <Route path="/log-in/" element={<UserLogin />} />
                    <Route path="/logout/" element={<Logout modId={modLoggedUserId} modName={modLoggedUserName}/>} />
                    <Route path="/profile/" element={<UserProfil loggedUserId={loggedUserId}/>} />
                    <Route path="/forgotten/" element={<ForgetPassword />} />
                    <Route path="/registering/" element={<UserRegistration />} />
                    <Route path="/registering-success/" element={<RegistrationSuccess />} />
                    <Route path="/verification/" element={<EmailVerificationSuccess />} />

                    
                    <Route path="/verification-success/" element={<VerificationSuccess />} />
                    <Route path="/verification-error/" element={<VerificationError />} />
                    <Route path="/" element={<Home/>} />
                </Routes>
            </LoggedContext.Provider>
        </BrowserRouter>
    )
}

export default App