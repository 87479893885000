import React from 'react'
import BgImage from "./dartboard.png"
import { useTranslation } from "react-i18next";
import "./VerificationError.css"

function VerificationError() {
  const { t } = useTranslation();
  return (
    <div className='main-error'>
        
        <div className='home_page_verification_error' style={
            {
              /* backgroundImage:'url(/dart2.svg)', */ 
              backgroundImage: `url(${BgImage})`, 
              backgroundRepeat:"no-repeat",
              backgroundSize:"cover",
              backgroundPosition:"center"
            }}>
        </div>

        <div className='verification-warning'>
          <h1>{t( "error_validation_message_1")}</h1>
          <p>{t( "error_validation_message_2")}</p>
          <p>{t( "error_validation_message_3")}</p>
        </div>

    </div>
  )
}

export default VerificationError
