import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
let API_URL = process.env.REACT_APP_API_URL

console.log("API_URL: ". API_URL)

const EmailVerificationSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        const token = new URLSearchParams(location.search).get('token');
        const user_id = new URLSearchParams(location.search).get('id');
        
        const verifyEmail = async () => {
          try {
            const response = await axios({
                method: "GET",
                url: `${API_URL}/verify/${user_id}/${token}/`,
              })
            
            const newdata = response.data
            newdata.verified = true
            const newresponse = await axios({
                method: "PUT",
                url: `${API_URL}/registermod/${user_id}/`,
                data: newdata
            })
            // Redirect the user to a success page or perform other actions
            navigate('/verification-success');
          } catch (error) {
            console.error('Failed to verify email:', error);
    
            // Redirect the user to an error page or perform other actions
            navigate('/verification-error');
          }
        };
    
        if (token) {
          verifyEmail();
        } else {
          console.error('Email verification token not found!');
          navigate('/verification-error');
        }
      }, [navigate, location.search]);

    return null
};

export default EmailVerificationSuccess;