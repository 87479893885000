import React, { useContext, useEffect} from 'react'
import './MainHeader.css'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../../locale/en/translation.json";
import translationHU from "../../locale/hu/translation.json";
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const resources = {
  en: {
    translation: translationEN,
  },
  hu: {
    translation: translationHU,
  },
};

i18n.use(initReactI18next).init({
resources,
lng: "en",
fallbackLng: "en",
interpolation: {
  escapeValue: false,
},
});

function MainHeader({loggedUserId=null, loggedUserName=""}) {
    const location = useLocation()
    const { t } = useTranslation();

    return (
    <div className={`main_header ${location.pathname==="/game/"?"not-main": ""}`}>
        <div className='menus'>
          {/* {console.log("Logged ID:", loggedUserId)}
          {console.log("Logged NAME: ", loggedUserName)} */}
          {loggedUserId > 0 ? <Link className={`nav-link ${location.pathname==="/newgamesettings/"?"active":""}`} to="/newgamesettings/">{t("new_game")}</Link>  : ""}
          {loggedUserId > 0 ? <Link className={`nav-link ${location.pathname==="/matchresults/"?"active":""}`} to="/matchresults/">{t("match_results")}</Link>  : ""}
          {loggedUserId > 0 ? <Link className={`nav-link ${location.pathname==="/playerresults/"?"active":""}`} to="/playerresults/">{t("player_results")}</Link>  : ""}
        </div>
        
        <div className='logged-user'>
          {loggedUserId > 0 ? <Link className={`nav-link ${location.pathname==="/profile/"?"active":""}`} to="/profile/">{loggedUserName}</Link> : ""}
        </div>

        <div className='login_corner'>
          {loggedUserId > 0 ? 
          <>
            <Link className={`nav-link ${location.pathname==="/logout/"?"active":""}`} to="/logout/">{t("logout")}</Link> 
            {<LanguageSwitcher />}
          </>
          : 
          <>
            <Link className={`nav-link ${location.pathname==="/log-in/"?"active":""}`} to="/log-in/">{t("login")}</Link> 
            <Link className={`nav-link ${location.pathname==="/registering/"?"active":""}`} to="/registering/">{t("register")}</Link>
            {<LanguageSwitcher />}
          </>
          }
        </div>
    </div>
  )
}

export default MainHeader
