import React from 'react'
import "./HotKeys.css"

function HotKeys(props) {
  return (
    <div>
        <div className={` ${props.fnkey === 'F9' || props.fnkey === 'F10' || props.fnkey === 'CTRL-B'? "extra_hotkey_container" : "hotkey_container"}`}>
            {/* <div className='hotkey_text'>{props.fnkey}:{props.value}</div> */}
            <div className={"hotkey_text"}>{props.fnkey}:{props.value}</div>
        </div>
    </div>
  )
}

export default HotKeys