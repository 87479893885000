import React, { useDebugValue } from 'react'
import { useTranslation } from "react-i18next";
/* import { useNavigate } from "react-router-dom"; */
import HotKeys from '../Micro/HotKeys'
import "./Footer.css"

function Footer() {
  const { t } = useTranslation();
    /* const navigate = useNavigate()
    "footer_hotkey_f9": "Maradt",
    "footer_hotkey_f10": "Kilép",
    "footer_hotkey_ctrl-b"
    
    */
    const hotkeys1 = [
        {"key" : "F1", "value" : "0"},
        {"key" : "F2", "value" : "26"},
        {"key" : "F3", "value" : "41"},
        {"key" : "F4", "value" : "45"},
        {"key" : "F5", "value" : "60"},
        {"key" : "F6", "value" : "81"},
        {"key" : "F7", "value" : "85"},               
    ]

    const hotkeys2 = [
      {"key" : "F8", "value" : "100"},
      {"key" : "F9", "value" : t("footer_hotkey_f9")},
      {"key" : "F10", "value" : t("footer_hotkey_f10")},
      {"key" : "CTRL-B", "value" : t("footer_hotkey_ctrl-b")},        
  ]    

  /* const handleClick = () => {
    navigate(`/`, {replace: true})
  } */

  return (
    <div className='footer'>
      <div className='footer_1'>
        {
            hotkeys1.map((item, index) => (                
                <HotKeys key={index} fnkey={item.key} value={item.value}/>
            ))}
      </div>
      <div className='footer_2'>
        {
            hotkeys2.map((item, index) => (                
                <HotKeys key={index} fnkey={item.key} value={item.value}/>
            ))}
      </div>
      {/* <div className='footer_3'>
        <button className='exit_button' onClick={handleClick} >EXIT</button>
      </div> */}
    </div>
  )
}

export default Footer