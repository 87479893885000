import { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';

const Logout = ({modId, modName}) => {
    const navigate = useNavigate()

    useEffect(() => {
        modId(null)
        modName("")
        navigate('/')
        window.localStorage.removeItem('loggedUserId', null)
        window.localStorage.removeItem('loggedUserName', "")
        window.localStorage.removeItem('login_time', 0)
    }, [])

    return null
}

export default Logout