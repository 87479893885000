import React, { useState } from 'react'
import PlayerName from '../Micro/PlayerName'
import ActResult from '../Micro/ActResult';
import "./Header.css"



function Header(props) {
  let active1 = "0";
    let active2 = "0";
    if (props.active === 1) {
        active1 = "1"
    }
    else {
        active2 = "1"
    }    
    /* if (props.width >= 376) {
      console.log("desktop")
    } else {
      console.log("mobile")
    } */
  return (
    <div className='header'>
        <PlayerName name={props.name1} active={active1}/>
        { props.width >= 600 ? <ActResult leg1={props.leg1} leg2={props.leg2} set1={props.set1} set2={props.set2}/> : "" }
        
        <PlayerName name={props.name2} active={active2}/>
    </div>
  )
}

export default Header