import React, { useState } from 'react'
import './PlayerName.css'

function PlayerName(props) {
    
    return (
        <div className={props.active === '1' ? 'name_container name_container_active' : 'name_container name_container_passive'}>
            <div className='name_text'>{props.name}</div>
        </div>
    )
}

export default PlayerName