import React, { Fragment } from 'react'
import './Home.css'
import MainHeader from '../MainHeader/MainHeader'
import Wizard from "./wizard.png"
import BgImage from "./dartboard.png"

function Home() {
  return (
    <div className='main'>
        
        <div className='home_page' style={
            {
              /* backgroundImage:'url(/dart2.svg)', */ 
              backgroundImage: `url(${BgImage})`, 
              backgroundRepeat:"no-repeat",
              backgroundSize:"cover",
              backgroundPosition:"center"
            
        }}>
            
        </div>
        <div className='picture'>
            <img src={Wizard} />
        </div>
    </div>
  )
}


export default Home
