import React from 'react'
import BgImage from "./dartboard.png"
import { useTranslation } from "react-i18next";
import "./VerificationSuccess.css"

function RegistrationSuccess() {
  const { t } = useTranslation();
  return (
    <div className='main-success'>
        
        <div className='home_page_verification' style={
            {
              /* backgroundImage:'url(/dart2.svg)', */ 
              backgroundImage: `url(${BgImage})`, 
              backgroundRepeat:"no-repeat",
              backgroundSize:"cover",
              backgroundPosition:"center"
            }}>
        </div>

        <div className='welcome'>
          <h1>{t("succes_registration_message_1")}</h1>
          <p>{t("succes_registration_message_2")}</p>
          <p>{t("succes_registration_message_3")}</p>
        </div>
    
    </div>
  )
}

export default RegistrationSuccess
