import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useTranslation } from "react-i18next";
import './MatchResults.css'
import { LoggedContext } from '../App';
import MatchStatus from './MatchStatus';
import MatchScores from './MatchScores';


function MatchResults() {
    let API_URL = process.env.REACT_APP_API_URL
    const loggedUserId = window.localStorage.getItem('loggedUserId')
    const { t } = useTranslation();
    const [matchList, setMatchList] = useState(null)            // mérkőzések listája
    const [playerList, setPlayerList] = useState()              // játékosok listája
    const [selectedMatch, setSelectedMatch] = useState(0)       // kiválasztott mérkőzés ID-a
    const [matchsettings, setMatchSettings] = useState()
    const [matchShoots, setMatchShoots] = useState()            // kiválasztott meccs dobásainak listája
    const [player1Name, setPlayer1Name] = useState("")      // 1. játékos neve, az ID: matchsettings.player1_id
    const [player2Name, setPlayer2Name] = useState("")

    // A bejelentkezett felhasználó mérkőzéseinek lekérdezése -> matchList
    useEffect(() => {
        getOwnerMatches()   // a tulaj által indított összes meccs
        getOwnerPlayers()   // a tulaj által regisztrált összes játékos
    }, [])

    function getOwnerPlayers () {
        axios({
            method: "GET",
            url: `${API_URL}/players_group/${loggedUserId}/`,
          }).then((response) => {
            const data = response.data  // itt van az összes tulaj által regisztrált játékos
            setPlayerList(data);
        })
    }

    function getOwnerMatches() {        
        axios({
            method: "GET",
            url: `${API_URL}/matches_group/${loggedUserId}/`,
          }).then((response) => {
            const data = response.data  // itt van az összes meccse a tulajnak, a beállítási adatokkal
            setMatchList(data);
        })
    }
    // Meccs beállítások lekérdezése -> matchsettings
    // Meccse dobásinak lekérdezése -> matchShoots
    useEffect(() => {
        if (selectedMatch > 0) {
            getMatchSettings(selectedMatch)
            getMatchScores(selectedMatch)
               
        } else {
            setMatchShoots()
        }
        
    }, [selectedMatch])

    const getMatchSettings = async (selectedMatch) => {        
        await axios({
            method: "GET",
            url: `${API_URL}/matchsettings_detail/${selectedMatch}/`,
            }).then((response) => {
            const data = response.data  
            setMatchSettings(data);
            getPlayerName1(data.player1_id)
            getPlayerName2(data.player2_id)
            /* console.log("státusban meccs részletek: ", data) */
        })
    }

    /* function getMatchDetail(matchId) {
        axios({
            method: "GET",
            url: `${API_URL}/matches_owner/${matchId}/`,
        }).then((response) => {
            const tempData = response.data
            setMatchDetail(tempData) // itt van a kiválasztott meccs leg, set, winner adatai
            console.log("match detail: ", tempData)
        })
    } */

    function getMatchScores(selectedMatch) {
        axios({
            method: "GET",
            url: `${API_URL}/matches_shoots/${selectedMatch}/`,
        }).then((response) => {
            const tempData = response.data
            setMatchShoots(tempData) // itt van a kiválasztott meccs dobott pontjainak listája
            console.log("shoots detail: ", tempData)
        })
    }

    function getPlayerName1(player1_id) {
        axios({
            method: "GET",
            url: `${API_URL}/players/${player1_id}/`,
          }).then((response) => {
            const data = response.data
            setPlayer1Name(data)
        })
    }

    function getPlayerName2(player2_id) {
        axios({
            method: "GET",
            url: `${API_URL}/players/${player2_id}/`,
          }).then((response) => {
            const data = response.data
            setPlayer2Name(data)
        })
    }
    const handleSelectOnchange = (e) => {
        setSelectedMatch(e.target.value)
        /* console.log("Kiválasztott mérkőzés id: ", e.target.value) */
        /* console.log("kiválasztott meccsen a player-1 :", playerList.find((obj => {return obj.id === 34})).player_name) */
    }

  return (
    <div className='match-results-screen'>
        <div className='match-results-title'>
        {t("match_results")}
        </div>
        
        <div className='match-stat-container'>
            <div className='match-container'>
                <div className='match-list-container'>
                    <b>Mérkőzések:</b> <br />
                    <div>
                        <select className='match-select'
                            value={selectedMatch}
                            onChange={(e) => handleSelectOnchange(e)}
                        >
                            <option value={0}>Kérem válasszon....</option>

                            {matchList && playerList ? (matchList.map((match) => (
                                <option 
                                    key={match.id} 
                                    value={match.id}
                                >
                                    {match.game_name} 
                                    -  
                                    {playerList.find((obj => {return obj.id === match.player1_id})).player_name} 
                                    - 
                                    {playerList.find((obj => {return obj.id === match.player2_id})).player_name} 
                                </option>
                            ))) : ""}
                            
                        </select>
                    </div>
                    <div className='match-filters'>
                        
                    </div>
                </div>
                <div className='match-status-container'>
                    <br/>
                    {selectedMatch > 0 && matchsettings ? <MatchStatus selectedMatch={selectedMatch} matchShoots={matchShoots}  matchsettings={matchsettings}  player1Name={player1Name} player2Name={player2Name}/> : ""}
                </div>
            </div>
            <div className='match-score-container'>
                
                {selectedMatch > 0 && matchsettings ? <MatchScores selectedMatch={selectedMatch} matchShoots={matchShoots} player1_ID={matchsettings.player1_id} player2_ID={matchsettings.player2_id}  player1Name={player1Name} player2Name={player2Name}/> : ""}
                
            </div>
        </div>
    </div>
  )
}

export default MatchResults
