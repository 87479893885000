import React from 'react'
import { useTranslation } from "react-i18next";
import './RoundAndScore.css'
import { useRef } from 'react';
import { useEffect } from 'react';

function RoundAndScore(props) {
  const shots_data1 = props.shots_data1
  const shots_data2 = props.shots_data2
  const side = props.side
  const round = props.round
  let shots_data
  let other_side_data
  let nbsp = "\u00A0"

  if (side == "left") {
    shots_data = shots_data1
    other_side_data = shots_data2
  } else {
    shots_data = shots_data2
    other_side_data = shots_data1
  }
  let other_side_length  = 0
  
  for (let i = 0; i < other_side_data.length; i++) {
    other_side_length += 1
    
  }

  const { t } = useTranslation();
  
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef()
    useEffect(() => elementRef.current.scrollIntoView())
    return <div ref={elementRef} />
  }

  return (
    <div className='round_and_score_container'>
        <div className='top-div'>
          <div className='table-head-container'>
            <table>
              <tbody>
                <tr>
                  <td className='left_column'>{t("game_round_row_label")}</td>
                  <td className='center_column'>{t("game_roundscore_label1")}</td>
                  <td className='right_column'>{t("game_roundscore_label2")}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='csak_tabla' id='csak_table'>
            <table>  
              <tbody>
                {shots_data && shots_data.map((item, index) => (
                  <tr key={index}>
                      <td className='left_column_d' align='center'>{item["number"]}</td>
                      <td className='center_column_d' align='center'>{item["point"]}</td>
                      <td className='right_column_d' align='center'>{item["value"]}</td>
                  </tr>
                ))}
                {
                other_side_data && other_side_length < round ? 
                "" : (<tr><td className='left_column_d' align='center'>{nbsp}</td></tr>)
                }
                <AlwaysScrollToBottom />
              </tbody>
            </table>
          </div>
        </div>
        <div className='bottom-div'>
          <br />
        </div>
    </div>
  )
}

export default RoundAndScore