import React, { useState } from 'react'
import "./ForgetPassword.css"
import axios from 'axios';
import BgImage from "./dartboard.png"
import { useTranslation } from "react-i18next";
let API_URL = process.env.REACT_APP_API_URL

function ForgetPassword() {
    const { t } = useTranslation();
    const [email, setEmail] = useState(null)
    const [wrongEmailMessage, setWrongEmailMessage] = useState("")
    
    const handleInputChange = (e) => {
        const {id , value} = e.target;
        if(id === "email"){
            setEmail(value);
        }
    }

    const generatePass = () => {
        let pass = '';
        let str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
            'abcdefghijklmnopqrstuvwxyz0123456789@#$';
     
        for (let i = 1; i <= 8; i++) {
            let char = Math.floor(Math.random()
                * str.length + 1);
     
            pass += str.charAt(char)
        }
     
        return pass;
    }
     
    const handleSendClick = async () => {
        let user_data = null
        let isGoodEmail = true
        await axios({
            method: "GET",
            url: `${API_URL}/forget/${email}/`,
        }).then((response) => {
            user_data = response.data
            console.log("emailből vissza: ", user_data)
            setWrongEmailMessage("")
        }).catch ((error) => {
            console.error('Hibás email:', error.response);
            if (error.response["status"] === 404) {
                console.log("404-es hiba")
                setWrongEmailMessage(t("forget_form_error_message"))
                isGoodEmail = false
            }
        }
        )
        
        if (isGoodEmail) {
            user_data['password'] = generatePass()
            
            const response = await axios.put(`${API_URL}/forget/${email}/`, {
                id: user_data.id,
                first_name: user_data.first_name,
                last_name: user_data.last_name,
                login_name: user_data.login_name,
                email: user_data.email,
                password: user_data.password,
                })
        }
      };

  return (
    <div className='main-forget'> 
        
            <div className='forget_page_login' style={
                {
                    /* backgroundImage:'url(/dart2.svg)', */ 
                    backgroundImage: `url(${BgImage})`, 
                    backgroundRepeat:"no-repeat",
                    backgroundSize:"cover",
                    backgroundPosition:"center"
                }}>
            </div>

            <div className='forget-page'>
                <div className='my-forget-form'>
                    <div className="my-forget-form-body">
                        <div className='form-title'>
                            <p>{t("forget_from_title")}</p>
                        </div>
                        <div className='email-error-message'>
                            {wrongEmailMessage}
                        </div>
                        <div className="email my-form-row">
                            <label className="forget-form-label" htmlFor="email">{t("forget_form_email_label")}</label>
                            <input  
                                type="email" 
                                autoFocus
                                value={email} 
                                id="email" 
                                placeholder={t("forget_form_email_hint")}
                                onChange = {(e) => handleInputChange(e)}
                            />
                        </div>
                        
                    </div>
                    <div className="footer-login">
                        <button onClick={handleSendClick} className="btn-forget">{t("forget_form_button_label")}</button>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default ForgetPassword
