import React from 'react'
import './Stats.css'

function Stats(props) {
  const stat_values = props.stat_values
  const stat_label = props.stat_label
  
  return (
    <div className='stat_container'>
        <table className='table_stat'>
          <tbody>
            {stat_label.map((item, index) => (
              <tr key={index}>
                <td className='left_column_stat' >{item["label"]}</td>
                <td className='right_column_stat' >{stat_values[index]["value"]}</td>
              </tr>
            ))}
          </tbody>
        </table>
    </div>
  )
}

export default Stats