import React from 'react'
import './ActScore.css'

function ActScore(props) {
  return (
    <div>
        <div className={props.active === '1' ? 'score_container score_container_active' : 'score_container score_container_passive'}>
            <div className='score_text'>{props.remainingScore}</div>
        </div>
    </div>
  )
}

export default ActScore