import React from 'react'
import { useTranslation } from "react-i18next";
import './CheckOuts.css'

function CheckOuts(props) {
  const { t } = useTranslation();

  return (
    <div className='check_outs_container'>
        <div className='check_out_text'>
            {t("game_checkout_label")}
        </div>
        <div className='check_out_items'>
            
            {props.hints.map((item) => (
                <div className='check_out_item'>{item}</div>
            ))}
                      
        </div>
        
    </div>
  )
}

export default CheckOuts