import React, { Fragment, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { remainingScore, undoLastScore } from '../../tools/toolFunctions';
import "./Keypad.css"

function Keypad(
            {
                getActive, 
                modActive,  
                getShotData1,
                getShotData2,  
                getScore1, 
                modScore1,
                getScore2, 
                modScore2,
                modScore, 
                modWasShot, 
                getScore,
                modHints1,
                modHints2,
                gameDataId,
                getStats1,
                modStats1,
                getStats2,
                modStats2,
                getSum1,
                modSum1,
                getSum2,
                modSum2,
                getSumDart1,
                modSumDart1,
                getSumDart2,
                modSumDart2,
                dartCount,
                getAvg1,
                modAvg1,
                getAvg2,
                modAvg2,
                round,
                legStart,
                getSum1_9,
                modSum1_9,
                getSum2_9,
                modSum2_9,
                getSumDart1_9,
                modSumDart1_9,
                getSumDart2_9,
                modSumDart2_9,
                modRound,
                getRoundData,
                modRoundData,
            }
        ) {
  const navigate = useNavigate()
  const [inputString, setInputString] = useState("")
  const [ isKeypadShow, setKeypadShow] = useState(true)
    /* const ref = useRef()

    useEffect(() => {
        ref.dispatchEvent(new KeyboardEvent('keypress', {
            key: 'F2',
        }))
    }, []) */

    /* document.addEventListener("keyup", (e) => {
        console.log(e)
    }) */

  const between = (x, min, max) => {
    return x >= min && x <=max
  }

  const handleKeypadPress = event => {
    console.log(event.target.name, ":", event.target.value)
    if (event.target.name === "btn-hide") {
      setKeypadShow(false)
    }
    if (event.target.name === "btn-show") {
      setKeypadShow(true)
    }
    if (event.target.name === "btn-enter") {
      modWasShot(true)
      setInputString("")
    }
    if (event.target.name === "btn-undo") {
        console.log("Keypad-visszavon")
        undoLastScore(
            getActive, 
            modActive, 
            getShotData1, 
            getShotData2, 
            getScore1, 
            modScore1, 
            getScore2, 
            modScore2,
            modHints1,
            modHints2,
            gameDataId, /* gameData.id */
            getStats1,
            modStats1,
            getStats2,
            modStats2,
            getSum1,
            modSum1,
            getSum2,
            modSum2,
            getSumDart1,
            modSumDart1,
            getSumDart2,
            modSumDart2,
            dartCount,
            getAvg1,
            modAvg1,
            getAvg2, 
            modAvg2,
            round,
            legStart,
            getSum1_9,
            modSum1_9,
            getSum2_9,
            modSum2_9,
            getSumDart1_9,
            modSumDart1_9,
            getSumDart2_9,
            modSumDart2_9,
            modRound,
            getRoundData,
            modRoundData,
            )
      }
    if (event.target.name === "btn-del") {
        if (inputString.length > 0) {
            let newInput = inputString.slice(0, -1)
            console.log("newinput: ", newInput)
            
            setInputString(newInput)
            /* inputString.slice(0, -1) */
            console.log("inputsting", inputString)
            modScore(Number(newInput))
            console.log("score", getScore())
        }
      }
    if (event.target.name === "btn-exit") { 
        navigate(`/`, {replace: true})
    }
    if (event.target.name === "btn-rem") { 
        /* const score1 = getScore1()
        const score2 = getScore2()
        const active = getActive() */
        remainingScore(modScore, modWasShot, getActive(), getScore1(), getScore2(), getScore)
        setInputString("")
    }
    if (between(event.target.value, 0, 9)) {
      let newInput = inputString + event.target.value
      if (!isNaN(newInput)) {
        if (newInput <= 180) {
          const score = Number(newInput)
          setInputString(newInput)
          modScore(score)
        }
      }
      
    }
  }
  return (
    <Fragment>
        { isKeypadShow ? 
        (<div className='virt-kbd'>
            <div className='kbd-row'>
                <div className='kbd-button'>
                    <button className='num-button' value={7} name='btn-7' onClick={handleKeypadPress}>7</button>
                </div>
                <div className='kbd-button'>
                    <button className='num-button' value={8} name='btn-8' onClick={handleKeypadPress}>8</button>           
                </div>
                <div className='kbd-button'>
                    <button className='num-button' value={9} name='btn-9' onClick={handleKeypadPress}>9</button>
                </div>
                <div className='kbd-button'>
                    <button className='num-button textbtn' value={"enter"} name='btn-enter' onClick={handleKeypadPress}>ENTER</button>   
                </div>
            </div>
            <div className='kbd-row'>
                <div className='kbd-button'>
                    <button className='num-button' value={4} name='btn-4' onClick={handleKeypadPress}>4</button>
                </div>
                <div className='kbd-button'>
                    <button className='num-button' value={5} name='btn-5' onClick={handleKeypadPress}>5</button>
                </div>
                <div className='kbd-button'>
                    <button className='num-button' value={6} name='btn-6' onClick={handleKeypadPress}>6</button>
                </div>
                <div className='kbd-button'>
                    <button className='num-button textbtn' value={"del"} name='btn-del' onClick={handleKeypadPress}>DEL</button>
                </div>
            </div>
            <div className='kbd-row'>
                <div className='kbd-button'>
                    <button className='num-button' value={1} name='btn-1' onClick={handleKeypadPress}>1</button>
                </div>
                <div className='kbd-button'>
                    <button className='num-button' value={2} name='btn-2' onClick={handleKeypadPress}>2</button>
                </div>
                <div className='kbd-button'>
                    <button className='num-button' value={3} name='btn-3' onClick={handleKeypadPress}>3</button>
                </div>
                <div className='kbd-button textbtn'>
                    <button className='num-button textbtn' value={"undo"} name='btn-undo' onClick={handleKeypadPress}>UNDO</button>
                </div>
            </div>
            <div className='kbd-row'>
                <div className='kbd-button'>
                    <button className='num-button' value={0} name='btn-0' onClick={handleKeypadPress}>0</button>
                </div>
                <div className='kbd-button textbtn'>
                    <button className='num-button textbtn' value={"hide"} name='btn-hide' onClick={handleKeypadPress}>HIDE</button>
                </div>
                <div className='kbd-button'>
                    <button className='num-button textbtn' value={"exit"} name='btn-exit' onClick={handleKeypadPress}>EXIT</button>
                </div>
                <div className='kbd-button'>
                    <button className='num-button textbtn' value={"rem"} name='btn-rem' onClick={handleKeypadPress}>REM</button>
                </div>
            </div>
        </div>
        ) : (
            <div className='virt-kbd-none'>
                <div className='kbd-row-none'>
                    <div className='kbd-button-none'>
                        <button className='show-button' value={"show"} name='btn-show' onClick={handleKeypadPress}>Show Keypad</button>
                    </div>
                </div>
            </div>
        )}
        
    </Fragment>
  )
}

export default Keypad
