import { deleteLastShot} from "./dbFunctions"

const chekOutsDict = [
    {170: ['T20-T20-DB']},
    {167: ['T20-T19-DB']},
    {164: ['T20-T18-DB', 'T19-T19-DB']},
    {161: ['T20-T17-DB']},
    {160: ['T20-T20-D20']},
    {158: ['T20-T20-D19']},
    {157: ['T20-T19-D20']},
    {156: ['T20-T20-D18']},
    {155: ['T20-T19-D19']},
    {154: ['T20-T18-D20', 'T19-T19-D20']},
    {153: ['T20-T19-D18']},
    {152: ['T20-T20-D16']},
    {151: ['T20-T17-D20', 'T19-T18-D20']},
    {150: ['T20-T18-D18', 'T19-T19-D18']},
    {149: ['T20-T19-D16']},
    {148: ['T20-T20-D14', 'T19-T17-D20']},
    {147: ['T20-T17-D18', 'T19-T19-D18']},
    {146: ['T20-T18-D16', 'T19-T19-D16']},
    {145: ['T20-T19-D14', 'T15-T20-D20']},
    {144: ['T20-T20-D12']},
    {143: ['T20-T17-D16', 'T19-T18-D16']},
    {142: ['T20-T14-D20', 'T19-T19-D14']},
    {141: ['T20-T19-D12', 'T17-T18-D18']},
    {140: ['T20-T20-D20']},
    {139: ['T20-T13-D20']},
    {138: ['T20-T18-D12' , 'T19-T19-D12']},
    {137: ['T20-T19-D10']},
    {136: ['T20-T20-D8']},
    {135: ['DB-T15-D20']},
    {134: ['T20-T16-D13' , 'T20-T14-D16']},
    {133: ['T20-T19-D8']},
    {132: ['DB-DB-D16']},
    {131: ['T20-T13-D16' , 'T19-T14-D16']},
    {130: ['T20-T20-D5']},
    {129: ['T19-T16-D12','T20-T19-D6']},
    {128: ['T18-T14-D16' , 'T18-T18-D10']},
    {127: ['T20-T17-D8']},
    {126: ['T19-T19-D6']},
    {125: ['T18-T13-D16' , 'T20-T15-D10']},
    {124: ['T20-T14-D11']},
    {123: ['T19-T16-D9']},
    {122: ['T18-T18-D7']},
    {121: ['T20-T11-D14' , 'T17-T20-D5']},
    {120: ['T20-S20-D20']},
    {119: ['T19-T12-D13']},
    {118: ['T20-S18-D20']},
    {117: ['T20-S17-D20' , 'T19-S20-D20']},
    {116: ['T19-S19-D20' , 'T20-S16-D20']},
    {115: ['T19-S18-D20' , 'T20-S15-D20']},
    {114: ['T20-S14-D20' , 'T19-S17-D20']},
    {113: ['T19-S16-D20' , 'T20-S13-D20']},
    {112: ['T20-T12-D8']},
    {111: ['T20-S11-D20' , 'T19-S14-D20']},
    {110: ['T20-T10-D10' , 'T19-S13-D20' , 'T20-DB']},
    {109: ['T20-S9-D20' , 'T19-T12-D8']},
    {108: ['T20-S16-D16' , 'T19-S11-D20']},
    {107: ['T19-T10-D10' , 'T19-DB']},
    {106: ['T20-T10-D8']},
    {105: ['T20-S13-D16']},
    {104: ['T19-S15-D16' , 'T18-DB']},
    {103: ['T19-S10-D18' , 'T19-S6-D20']},
    {102: ['T20-S10-D16']},
    {101: ['T20-S9-D16' , 'T19-T12-D4' , 'T17-DB']},
    {100: ['T20-D20']},
    {99: ['T19-S10-D16']},
    {98: ['T20-D19']},
    {97: ['T19-D20']},
    {96: ['T20-D18']},
    {95: ['SB-T20-D5' , 'T19-D19']},
    {94: ['SB-T19-D6' , 'T18-D20']},
    {93: ['SB-T18-D7' , 'T19-D18']},
    {92: ['T20-D16' , 'SB-T17-D8']},
    {91: ['T17-D20' , 'SB-T16-D9']},
    {90: ['T18-D18' , 'T20-D15']},
    {89: ['T19-D16']},
    {88: ['T20-D14' , 'T16-D20']},
    {87: ['T17-D18']},
    {86: ['T18-D16']},
    {85: ['T19-D14' , 'T15-D20']},
    {84: ['T20-D12']},
    {83: ['T17-D16']},
    {82: ['T14-D20' , 'DB-D16']},
    {81: ['T19-D12' , 'T15-D18']},
    {80: ['T20-D10' , 'T16-D16']},
    {79: ['T19-D11' , 'T13-D20']},
    {78: ['T18-D12']},
    {77: ['T19-D10']},
    {76: ['T20-D8' , 'T16-D14']},
    {75: ['T17-D12' , 'SB-DB']},
    {74: ['T14-D16']},
    {73: ['T19-D8']},
    {72: ['T16-D12' , 'T12-D18' , 'T20-D6']},
    {71: ['T13-D16' , 'T17-D10' , 'T19-D7']},
    {70: ['T18-D8' , 'T20-D5']},
    {69: ['T19-D6']},
    {68: ['T16-D10' , 'T20-D4' , 'T18-D7']},
    {67: ['T17-D8' , 'T9-D20']},
    {66: ['T10-D18' , 'T18-D6' , 'T16-D9']},
    {65: ['T19-D4' , 'T15-D10' , 'SB-D20']},
    {64: ['T16-D8' , 'T14-D11']},
    {63: ['T17-D6' , 'T13-D12']},
    {62: ['T10-D16' , 'T12-D13']},
    {61: ['T15-D8' , 'T11-D14']},
    {60: ['S20-D20']},
    {59: ['S19-D20']},
    {58: ['S18-D20']},
    {57: ['S17-D20']},
    {56: ['S16-D20' , 'T16-D4']},
    {55: ['S15-D20']},
    {54: ['S14-D20' , 'S18-D18']},
    {53: ['S13-D20' , 'S17-D18']},
    {52: ['S12-D20' , 'S20-D16' , 'T12-D8']},
    {51: ['S11-D20' , 'S19-D16' , 'S15-D18']},
    {50: ['S10-D20' , 'S18-D16']},
    {49: ['S9-D20' , 'S17-D16']},
    {48: ['S16-D16' , 'S8-D20']},
    {47: ['S15-D16' , 'S7-D20']},
    {46: ['S6-D20' , 'S10-D18']},
    {45: ['S13-D16' , 'S5-D20']},
    {44: ['S12-D16' , 'S4-D20']},
    {43: ['S11-D16' , 'S3-D20']},
    {42: ['S10-D16' , 'S6-D18']},
    {41: ['S9-D16']},
    {40: ['D20']},
    {39: ['S7-D16' , 'S19-D10' , 'S3-D18']},
    {38: ['D19' , 'S6-D16' , 'S10-D14']},
    {37: ['S5-D16']},
    {36: ['D18']},
    {35: ['S3-D16' , 'S19-D8']},
    {34: ['D17' , 'S6-D14' , 'S10-D12']},
    {33: ['S1-D16' , 'S17-D8' , 'S9-D12']},
    {32: ['D16']},
    {31: ['S15-D8']},
    {30: ['D15' , 'S10-D10']},
    {29: ['S13-D8' , 'S9-D10']},
    {28: ['D14']},
    {27: ['S19-D4' , 'S7-D10' , 'S11-D8']},
    {26: ['D13']},
    {25: ['S17-D4' , 'S9-D8' , 'S5-D10']},
    {24: ['D12']},
    {23: ['S7-D8']},
    {22: ['D11']},
    {21: ['S5-D8' , 'S1-D10' , 'S17-D2']},
    {20: ['D10']},
    {19: ['S11-D4' , 'S3-D8']},
    {18: ['D9']},
    {17: ['S9-D4' , 'S1-D8']},
    {16: ['D8']},
    {15: ['S7-D4']},
    {14: ['D7' , 'S6-D4' , 'S10-D2']},
    {13: ['S5-D4']},
    {12: ['D6']},
    {11: ['S3-D4']},
    {10: ['D5' , 'S2-D4']},
    {9: ['S1-D4']},
    {8: ['D4']},
    {7: ['S3-D2']},
    {6: ['D3' , 'S2-D2']},
    {5: ['S1-D2']},
    {4: ['D2']},
    {3: ['S1-D1']},
    {2: ['D1']}
]


export function checkValidShot(shot, akt_score) { 
    if (shot === 0 || (shot + 1 === akt_score) || shot > akt_score) {
        return 0
    } else if (shot < akt_score) {
        return 1
    } else {
        return 2
    }
}

export function checkOuts(modHintsFn, score) {
    const hint = []
    const ered = chekOutsDict.find(item => item[score])
    if (ered) {
        /* console.log("CHECK:", ered[score])
        for (let index = 0; index < ered[score].length; index++) {
            hint[index] = ered[score][index]            
        } */
        modHintsFn(ered[score])
    } else {
        modHintsFn(hint)
    }
    
    
}

export function updateShotStat(getFn, setFn, score, back=false) {
    const orig_stat = getFn()
    let increment = 1
    if (back) {
        increment = -1
    }
    /* console.log("Increment: ", increment) */
    if (score >= 60 && score < 80 ) {
        orig_stat[0]["value"] += increment 
    } else if (score >= 80 && score < 100 ) {
        orig_stat[1]["value"] += increment
    } else if (score >= 100 && score < 120 ) {
        orig_stat[2]["value"] += increment
    } else if (score >= 120 && score < 140 ) {
        orig_stat[3]["value"] += increment
    } else if (score >= 140 && score < 180 ) {
        orig_stat[4]["value"] += increment
    } else if (score === 180) {
        orig_stat[5]["value"] += increment
    }
    
    setFn(orig_stat)
}

export function updateAvg(getSumFn, modSumFn, getSumDartFn, modSumDartFn, dartCount, getAvgFn, modAvgFn, score, round, back=false) {
    let sum_temp = getSumFn()  /* Integer - dobott összeg */
    const avg_temp = getAvgFn()   /* Array - max, best, avg, 9nyilas */
    let sum_dart = getSumDartFn()
    if (!back) {
        sum_temp += Number(score)
        sum_dart += dartCount 
    } else {
        sum_temp -= Number(score)
        sum_dart -= dartCount
    }
    /* Átlagszámítás ( tömb[2]) */
    if (sum_dart > 0) {
        avg_temp[2]["value"] = Number(((sum_temp / sum_dart) * 3).toFixed(2)) /* Alapban 1 nyilas átlag, ezért *3 */    
    } else {
        avg_temp[2]["value"] = 0
    }
    
    modSumFn(sum_temp)  /* Visszaírjuk az összeget */
    modAvgFn(avg_temp)  /* Visszaírjuk az átlagot */
    modSumDartFn(sum_dart) /* Visszaírjuk az össz nyíl-számot */
}

export function update9Avg(getSumFn, modSumFn, getSumDart9Fn, modSumDart9Fn, getAvgFn, modAvgFn, score, back=false) {
    /* console.log("UPDATE 9") */
    let sum_temp = getSumFn()  /* Integer - dobott összeg */
    const avg_temp = getAvgFn()   /* Array - max, best, avg, 9nyilas */
    let sum_dart = getSumDart9Fn()
    /* console.log("sum_temp: ", sum_temp)
    console.log("avg_temp: ", avg_temp)
    console.log("sum_dart: ", sum_dart) */
    if (!back) {
        sum_temp += Number(score)
        sum_dart += 3  
    } else {
        sum_temp -= Number(score)
        sum_dart -= 3
    }
    /* 9 nyilas ( tömb[3]) */
    if (sum_dart > 0) {
        avg_temp[3]["value"] = Number(((sum_temp / sum_dart) * 3).toFixed(2)) /* Alapban 1 nyilas átlag, ezért *3 */    
    } else {
        avg_temp[3]["value"] = 0
    }
    
    /* console.log("sum_temp after: ", sum_temp)
    console.log("avg_temp after: ", avg_temp)
    console.log("sum_dart after: ", sum_dart) */

    modSumFn(sum_temp)  /* Visszaírjuk az összeget */
    modAvgFn(avg_temp)  /* Visszaírjuk az átlagot */
    modSumDart9Fn(sum_dart) /* Visszaírjuk az össz nyíl-számot */
}

export function updateRoundList(getShotDataFn, modShotDataFn, round_number, score, prevScore) {
    let shotData = getShotDataFn()
    const item = {"number": "", "point": "", "value": ""}
    /* const item = {"point": "", "value": ""} */

    item.number = round_number *3
    item.point = score
    item.value = prevScore - score
    shotData.push(item)
    /* console.log("shotdata updateroundlist-ben:", shotData) */
    modShotDataFn(shotData)
}

export function emptyRoundList(modShotDataFn) {
    modShotDataFn([])
}

export function updateBestLegCheckouts(round_number, getAvgFn, modAvgFn, dartCount, score) {
    const avg_temp = getAvgFn()   /* Array - max, best, avg, 9nyilas */   
    let total_dart_count = ((Number(round_number) -1) * 3 ) + Number(dartCount)
    
    if (score > avg_temp[0]["value"]) {
        avg_temp[0]["value"] = score
    }
    if (avg_temp[1]["value"] === 0 || avg_temp[1]["value"] > total_dart_count) {
        avg_temp[1]["value"] = total_dart_count
    } 

    modAvgFn(avg_temp)  /* Visszaírjuk az átlagot */

}
export const getWindowDimension = () => {
    const width = window.innerWidth 
              || document.documentElement.clientWidth
              || document.body.clientWidth;
    const height = window.innerHeight
              || document.documentElement.clientHeight
              || document.body.clientHeight;
    return {width, height}
    }

export function remainingScore(modScoreFn, modWasShotFn, active, score1, score2, getScoreFn) {
    let score = getScoreFn()
    if (active === 1) {
        if ((score1 > score) && (score1 - score  <= 180)) {
            const new_score =score1 - score
            modScoreFn(new_score)
            modWasShotFn(true)
        }
      } else {
        if ((score2 > score) && (score2 - score <= 180)) {
            const new_score =score2 - score
          modScoreFn(new_score)
          modWasShotFn(true)
        }
      }
}

export function undoLastScore(
        getActiveFn, 
        modActiveFn, 
        getShotData1Fn,
        /* modShotData1Fn,  */
        getShotData2Fn,
        /* modShotData2Fn,  */
        getScore1Fn, 
        modScore1Fn, 
        getScore2Fn, 
        modScore2Fn,
        modHints1Fn,
        modHints2Fn,
        gameDataId, /* gameData.id */
        getStats1Fn,
        modStats1Fn,
        getStats2Fn,
        modStats2Fn,
        getSum1Fn,
        modSum1Fn,
        getSum2Fn,
        modSum2Fn,
        getSumDart1Fn,
        modSumDart1Fn,
        getSumDart2Fn,
        modSumDart2Fn,
        dartCount,
        getAvg1Fn,
        modAvg1Fn,
        getAvg2Fn, 
        modAvg2Fn,
        round,
        legStart,
        getSum1_9Fn,
        modSum1_9Fn,
        getSum2_9Fn,
        modSum2_9Fn,
        getSumDart1_9Fn,
        modSumDart1_9Fn,
        getSumDart2_9Fn,
        modSumDart2_9Fn,
        modRoundFn,
        /* getRoundDataFn,
        modRoundDataFn, */
        ) 
    {
    const shot_data1 = getShotData1Fn()
    const shot_data2 = getShotData2Fn()
    const active = getActiveFn()
    if (active === 2) {
        if (shot_data1.length > 0) {
          const orig_shotdata = shot_data1
          /* console.log("orig_shotdata értékadás után", orig_shotdata)
          console.log("shot_data1 pop előtt", shot_data1) */
          const last_score = orig_shotdata.pop()
          /* console.log("orig_shotdata pop után", orig_shotdata)
          console.log("shot_data1 pop után", shot_data1) */
          /* modShotData1Fn(orig_shotdata) */ // ez eddig nem volt, és mégis működött
          const new_score = getScore1Fn() + last_score['point']
          modScore1Fn(new_score)
          checkOuts(modHints1Fn, new_score)
          deleteLastShot(gameDataId)
          updateShotStat(getStats1Fn, modStats1Fn ,last_score['point'], true)
          updateAvg(getSum1Fn, modSum1Fn, getSumDart1Fn, modSumDart1Fn , dartCount, getAvg1Fn, modAvg1Fn, last_score['point'], round, true)
          if (legStart === 1) {
            if (round <= 3) {
              update9Avg(getSum1_9Fn, modSum1_9Fn, getSumDart1_9Fn, modSumDart1_9Fn, getAvg1Fn, modAvg1Fn, last_score['point'], true)
            } 
          } else {
            if (round <= 4) {
              update9Avg(getSum1_9Fn, modSum1_9Fn, getSumDart1_9Fn, modSumDart1_9Fn, getAvg1Fn, modAvg1Fn, last_score['point'], true)
            }
          }
          modActiveFn(1)
          if (legStart === 2 && round > 1) {
            modRoundFn((prev) => (prev - 1)) /* xxxxxxxxxx  Itt vajon mi lesz, ha ???? Kell ellenőrizni?           xxxxxxxxxxx  */
          } 
          /* else {
            let round_data = getRoundDataFn()
            round_data.pop()
            modRoundDataFn(round_data)
          } */
        }          
      }  else {
        if (shot_data2.length >0) {
          const orig_shotdata = shot_data2
          const last_score = orig_shotdata.pop()
          /* modShotData2Fn(orig_shotdata) */
          const new_score = getScore2Fn() + last_score['point']
          modScore2Fn(new_score)
          checkOuts(modHints2Fn, new_score)
          deleteLastShot(gameDataId)
          updateShotStat(getStats2Fn, modStats2Fn ,last_score['point'], true)
          updateAvg(getSum2Fn, modSum2Fn, getSumDart2Fn, modSumDart2Fn , dartCount, getAvg2Fn, modAvg2Fn, last_score['point'], round, true)
          if (legStart === 1) {
            if (round <= 4) {
              update9Avg(getSum2_9Fn, modSum2_9Fn, getSumDart2_9Fn, modSumDart2_9Fn, getAvg2Fn, modAvg2Fn, last_score['point'], true)
            }
          } else {
            if (round <= 3) {
              update9Avg(getSum2_9Fn, modSum2_9Fn, getSumDart2_9Fn, modSumDart2_9Fn, getAvg2Fn, modAvg2Fn, last_score['point'], true)
            }
          }
          
          modActiveFn(2)
          if (legStart === 1 && round > 1) {
            modRoundFn((prev) => (prev - 1))
          } 
          /* else {
            let round_data = getRoundDataFn()
            round_data.pop()
            modRoundDataFn(round_data)
          } */
        }          
      }
}