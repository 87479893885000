import { useEffect, useState } from "react"
import React  from 'react'
import axios from 'axios'
import "./MatchScores.css"

function MatchScores({selectedMatch, matchShoots, player1_ID, player2_ID, player1Name, player2Name}) {
  let API_URL = process.env.REACT_APP_API_URL
  let data = null
  let groupedData = null
  const PLAYER1_ID = player1_ID;
  const PLAYER2_ID = player2_ID;

  if (matchShoots) {
    data = matchShoots
  }

  if (data) {
    groupedData = {};
    data.forEach((row) => {
      const { set_id, leg_id, player_id, round_number, points } = row;
      const key = `${set_id}-${leg_id}`;

      if (!groupedData[key]) {
        groupedData[key] = { set_id, leg_id, rounds: {} };
      }

      if (!groupedData[key].rounds[round_number]) {
        groupedData[key].rounds[round_number] = { points1: null, points2: null };
      }
      
      if (player_id === PLAYER1_ID) {
        groupedData[key].rounds[round_number].points1 = points;
      } else if (player_id === PLAYER2_ID) {
        groupedData[key].rounds[round_number].points2 = points;
      }
    });
  }
  

  return (
    <div>
      {groupedData && Object.values(groupedData).map(({ set_id, leg_id, rounds }) => (
        <div className="leg-result-container" key={`${set_id}-${leg_id}`}>
          <h2>{`Set ${set_id} Leg ${leg_id}`}</h2>
          <div className='two-tables'>
            <div className='table-one'>
              <table className="table-score1">
                <thead>
                  <tr className="th-row-1">
                    <th className="col1-1">Dart</th>
                    <th className="col2-1">{`${player1Name.player_name} Points`}</th>
                    {/* <th>{`Player ${PLAYER2_ID} Points`}</th> */}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(rounds).map(([round_number, { points1, points2 }]) => (
                    <>
                      {points1 !== null ? (<tr className="row-1" key={round_number}>
                        <td className="col1-1">{round_number}</td>
                        <td className="col2-1">{points1}</td>
                      </tr>) : null}
                      
                    </>
                  ))}
                </tbody>
              </table>
              {/* <div></div> */}
            </div>
            <div className='table-one'>
              <table className="table-score2">
                <thead>
                  <tr className="th-row-2">
                    <th className="col1-2">Dart</th>
                    {/* <th>{`Player ${PLAYER1_ID} Points`}</th> */}
                    <th className="col2-2">{`${player2Name.player_name} Points`}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(rounds).map(([round_number, { points1, points2 }]) => (
                    <>
                    {points2 !== null ? (<tr className="row-2" key={round_number}>
                      <td className="col1-2">{round_number}</td>
                      <td className="col2-2">{points2}</td>
                    </tr>) : null}
                    
                  </>
                  ))}
                </tbody>
              </table>
              {/* <div></div> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MatchScores
