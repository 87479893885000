import React, { Fragment } from 'react'
import './GameState.css'
import ActScore from '../Micro/ActScore'
import ActResult from '../Micro/ActResult';

function ActState(props) {
    let active1 = "0";
    let active2 = "0";
    
    if (props.active === 1) {
        active1 = "1"
    }
    else {
        active2 = "1"
    }
    /*console.log(props.active, active0, active1)*/
  return (
    <div className='act_state_container'>
        
        { props.width >= 600 ? "" : (
          <Fragment>
            <ActScore remainingScore={props.score1} active={active1} />
            <ActResult leg1={props.leg1} leg2={props.leg2} set1={props.set1} set2={props.set2}/>
            <ActScore remainingScore={props.score2} active={active2} /> 
          </Fragment>
          

        )}
        {/* <ActResult leg1={props.leg1} leg2={props.leg2} set1={props.set1} set2={props.set2}/> */}
        
    </div>
  )
}

export default ActState