import axios from 'axios';
let API_URL = process.env.REACT_APP_API_URL

export function deleteLastShot(match_id) {
  axios({
    method: "GET",
    url: `${API_URL}/lastshot/${match_id}/`,
  }).then((response) => {
      const data = response
    }).catch((error) => {
        if( error.response ){
            console.log(error.response.data); // => the response payload 
            console.log(error.response.status);  
            console.log(error.response.headers);
            
        }
      })
}

export function registerUserToDb(firstName,lastName,email,password, verificationToken) {
  axios({
    method: "POST",
    url: `${API_URL}/register/`,
    data: {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      token: verificationToken
    }
    
  }).then((response) => {
    const data = response.data
    console.log("Kiírt regisztráció", data)
  }).catch((error) => {
    if( error.response ){
        console.log(error.response.data); // => the response payload 
        console.log(error.response.status);  
        console.log(error.response.headers);
        alert(error.response.data["email"][0])
    }
})
}

export function shotToDb(player_id, score, leg_id, set_id, match_id, dart) {
    axios({
        method: "POST",
        /* url: "http://localhost:8000/shots/", */
        url: `${API_URL}/shots/`,
        data: {
          player_id: player_id,
          points: score,
          leg_id: leg_id,
          set_id: set_id,
          match_id: match_id,
          round_number: dart
        }
      }).then((response) => {
        const data = response.data
        console.log("Kiírt dobás:", data)        
      })
}

export function matchToDb(match_id, leg_id, set_id, winner_id) {
  axios({
      method: "POST",
      /* url: "http://localhost:8000/matches/", */
      url: `${API_URL}/matches/`,
      data: {
        match_id: match_id,
        leg_id: leg_id,
        set_id: set_id,
        winner_id: winner_id
      }
    }).then((response) => {
      const data = response.data
      /* console.log("Kiírt meccs:", data) */      
    })
}

export function getPlayerName(player_id, setPlayerNameFn) {
  axios({
      method: "GET",
      url: `${API_URL}/players/${player_id}/`,
    }).then((response) => {
      const data = response.data
      setPlayerNameFn(data["player_name"])
  })
}