import React from 'react'
import './ActResult.css'

function ActResult(props) {
  return (
    <div className='act_result_container'>
        <div className='p1_result'>
            <div>
                {props.leg1}
            </div>
            <div>
                {props.set1}
            </div>
            
        </div>
        <div className='text_result'>
            <div>
                Legs
            </div>
            <div>
                Sets
            </div>            
        </div>
        <div className='p2_result'>            
            <div>
                {props.leg2}
            </div>
            <div>
                {props.set2}
            </div>            
        </div>
    </div>
  )
}

export default ActResult