import React from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSwitcher.css"
import HuFlag from "./hun.png"
import UkFlag from "./uk.png"

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = () => {
    i18n.changeLanguage("hu");
  };
  const handleLanguageChange2 = () => {
    i18n.changeLanguage("en");
  };

  return (
    
    <div className="languages">
      <img src={HuFlag} id="hu-flag" onClick={handleLanguageChange}/>
      <img src={UkFlag} id="uk-flag" onClick={handleLanguageChange2}/>
      {/* <select value={i18n.language} onChange={handleLanguageChange}>
      <option value="en">English</option>
      <option value="hu">Magyar</option>
    </select> */}
    </div>



   
  );
};

export default LanguageSwitcher;