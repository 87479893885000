/* import "react-widgets/styles.css" */
import "../../css/ReactWidgets.css"
import "./MatchSettings.css"
import { Combobox } from "react-widgets";
import {useState, useEffect, useContext} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ButtonBackGround from "./dart2.jpg"
import BgImage from "./dartboard.png"
import { useTranslation } from "react-i18next";
/* import { LoggedContext } from "../App"; */

function MatchSettingsForm({loggedUserId, loggedUserName}) {
    let API_URL = process.env.REACT_APP_API_URL
    let ROOT_URL = process.env.REACT_APP_ROOT
    /* const {loggedUserId, setLoggedUserId, loggedUserName, setLoggedUserName} = useContext(LoggedContext) */
    const url_players = `${API_URL}/players/`
    const url_players_group = `${API_URL}/players_group/${loggedUserId}/`
    const url_matchsettings = `${API_URL}/matchsettings/`
    const image_url = `${ROOT_URL}/dart2.svg`
    const { t } = useTranslation();
    const delay = ms => new Promise(
      resolve => setTimeout(resolve, ms)
    )
    const [selectItems , setSelectItems] = useState(   /* Ide kerülnek a db-ből lekért adatok */
        [
        {
        id: "",
        player_name: ""
    }])
    const navigate = useNavigate()
    const currentDate = new Date().toLocaleDateString()
    const currentTime = new Date().toLocaleTimeString()
    const currentDateTime = String(currentDate)+String(currentTime)
    const [formData, setFormData] = useState({
      player1_id: null,
      player2_id: null,
      owner_id: loggedUserId,
      game_name: loggedUserName + "-" + currentDateTime,
      variant: 501,
      sets: 0,
      legsperset: 0,
      hc1: 0,
      hc2: 0,
    })
    const [value1, setValue1] = useState(    /* Ezt használjuk a FORM inpur frissítéshez */
        {
          "id": null,
          "player_name": null
        }
    )
    const [value2, setValue2] = useState(    /* Ezt használjuk a FORM inpur frissítéshez */
        {
          "id": null,
          "player_name": null
        }
    )
    const [selectedOption, set_selectedOption] = useState("501")

    useEffect(() => {
      getPlayerNames();
      setFormData({
        ...formData,
        owner_id: loggedUserId,
        game_name: loggedUserName + "-" + currentDateTime
    })
      } ,[loggedUserId])

    function getPlayerNames() {
        
        axios({
            method: "GET",
            url: url_players_group,
          }).then((response) => {
            const data = response.data
            setSelectItems(data);
        })
    }
    
    function onChange1(data) {  /* FORM input (value STATE) frissítése, ha változik */
      if (typeof data === "object") {
        /* Ha object, vagyis listából jön, akkor directben beállítható a STATE és a Formdata-ban az id */
        setValue1({...value1, id: data["id"] , player_name: data["player_name"]})
        setFormData({...formData, player1_id: data["id"] })
      } else {
        /* Ha nem listából választ, hanem gépel, akkor az id=null (default), és csak a nevet kell
        a gépelésnek megfelelően folyamatosan módosítani.
        DE!! Ha előtte listából választott, akkor az id != null. Ha csak a nevet változtatnánk, akkor 
        az előző "listás" id megmaradna, ezért azt vissza kell rakni a kezdeti null-ra. */
        setValue1({...value1,  id: null, player_name: data})
      }
    }
    
    function onChange2(data) {  /* FORM input (value STATE) frissítése, ha változik */
      if (typeof data === "object") {
        setValue2({...value2, id: data["id"] , player_name: data["player_name"]})
        setFormData({...formData, player2_id: data["id"] })
      } else {
        setValue2({...value2, id: null,   player_name: data})
      }
    }

    const handleChange = (event) => {
      setFormData({
          ...formData,
          [event.target.name]: event.target.value
      });
    }

    const handleOptionChange = changeEvent => {
      set_selectedOption(changeEvent.target.value)
      setFormData({
          ...formData,
          variant: changeEvent.target.value});
    };
    

    const handleSubmit = async event => {
        event.preventDefault()
        console.log(' 1 ID:', value1["id"])
        console.log("1. name: ", value1["player_name"])
        console.log(' 2 ID:', value2["id"])
        console.log("2. name: ", value2["player_name"])
        if (value1["id"] != null) {    
          /* Ha a Combobox value == dict, és van "id", akkor a listából választott  */
        }
        else {    
          if (value1["player_name"] && value1["player_name"].length) {
            axios.post(url_players, {        
              player_name: value1["player_name"],
              owner_id: loggedUserId
            })
            .then(res => {
              let response = res.data
              value1.id = response["id"]
              formData.player1_id = response["id"]
            })  
          } else {
            // Kell az adott Player_1 id-a !!!!
            selectItems.map((item) => {
              if (item["player_name"] === "Player_1") {
                formData.player1_id = item["id"]
              }
            })
          }
          
          /* Ha nics "id", akkor újként beszúrjuk a db-be. A .then-ben a res(ponse) tartalmazza a beszúrt rekord-ot */
          
        }
        if (value2["id"]) {    
          /* Ha a Combobox value == dict, és van "id", akkor a listából választott  */
        }
        else {    
          if (value2["player_name"] && value2["player_name"].length) {
            axios.post(url_players, {        
              player_name: value2["player_name"],
              owner_id: loggedUserId
            })
            .then(res => {
              let response = res.data
              value2.id = response["id"]
              formData.player2_id = response["id"]
            })  
          } else {
            selectItems.map((item) => {
              if (item["player_name"] === "Player_2") {
                formData.player2_id = item["id"]
              }
            })
          }
          /* Ha nics "id", akkor újként beszúrjuk a db-be. A .then-ben a res(ponse) tartalmazza a beszúrt rekord-ot */
          
        }
        await delay(500)
        axios.post(url_matchsettings, {
          player1_id: formData.player1_id,
          player2_id: formData.player2_id,
          owner_id: loggedUserId,
          game_name: formData.game_name,
          variant: formData.variant,
          sets: formData.sets,
          legsperset: formData.legsperset,
          hc11: formData.hc1,
          hc2: formData.hc2
        })
        .then(res => {
          const tempdata = formData
          tempdata["id"] = res.data["id"]
          setFormData(tempdata)
        })
        await delay(300)
        /* Átirányítás új oldalra, ha kész */
        console.log("MATCHSETTINGS adta:", formData)
        navigate(`/game/`, {replace: true, state: {data: formData}})
    }    

  return (
    <div className="settings-screen">

      <div className='home_page_settings' style={
                {
                /* backgroundImage:'url(/dart2.svg)', */ 
                backgroundImage: `url(${BgImage})`, 
                backgroundRepeat:"no-repeat",
                backgroundSize:"cover",
                backgroundPosition:"center"
                
        }}>
      </div>

      <div className="form-container">
        <form className="settings-form" onSubmit={handleSubmit}>
        <div className="text-for-input">
          <b>{t("matchsettings_gamename_label")}</b>{t("matchsettings_gamename_comment")}
        </div>
        <div className="form-row-gamename">
            <input 
              className="gamename"
              type="text" 
              name="game_name" 
              value={formData.game_name} 
              onChange={handleChange}
            />
            </div>
          <div className="text-for-input">
            <b>{t("matchsettings_player1_label")}</b>
          </div>
          <div className="form-row-player">
            <Combobox
              name="player1"
              hideCaret
              hideEmptyPopup
              autoFocus
              data={selectItems}
              dataKey='id'
              textField='player_name'
              value={value1["player_name"]}
              onChange={(field_data) => onChange1(field_data)}
              placeholder={t("matchsettings_player1_placeholder")}
            />
          </div>
          <div className="text-for-input">
            <b>{t("matchsettings_player2_label")}</b>
          </div>
          <div className="form-row-player">
            <Combobox
              name="player2"
              hideCaret
              hideEmptyPopup
              data={selectItems}
              dataKey='id'
              textField='player_name'
              value={value2["player_name"]}
              onChange={(field_data) => onChange2(field_data)}
              placeholder={t("matchsettings_player2_placeholder")}
            />
          </div>
            
            <div className="form-row-variant">
              {t("matchsettings_variant_label")}
              <div className="variant-item">
                <input
                  type="radio"
                  name="variant"
                  id=""
                  value="170"
                  checked={selectedOption === "170"}
                  onChange={handleOptionChange}
                />
                170
            </div> 
            <div className="variant-item">
              <input 
                  type="radio" 
                  name="variant" 
                  id="" 
                  value="301"
                  checked={selectedOption === "301"}
                  onChange={handleOptionChange}
              />
              301
            </div> 
            <div className="variant-item">
              <input 
                  type="radio" 
                  name="variant" 
                  id="" 
                  value="501"
                  checked={selectedOption === "501"}
                  onChange={handleOptionChange}
              />
              501
            </div>
            </div>
            <div className="form-row">
                {t("matchsettings_legnumber_label")}
                <input 
                  type="text" 
                  name="legsperset" 
                  value={formData.legsperset} 
                  onChange={handleChange}
                />
            </div>
            <div className="form-row">
                {t("matchsettings_setnumber_label")}
                <input 
                  type="text" 
                  name="sets" 
                  value={formData.sets} 
                  onChange={handleChange}
                />
            </div>
            <div className="form-row">
                {t("matchsettings_handicap_label1")}
                <input 
                  type="text" 
                  name="hc1" 
                  value={formData.hc1} 
                  onChange={handleChange}
                />
            </div>
            <div className="form-row">
                {t("matchsettings_handicap_label2")}
                <input 
                  type="text" 
                  name="hc2" 
                  value={formData.hc2} 
                  onChange={handleChange}
                />
            </div>
            {/* <div className="form-row-button"  style={{backgroundImage: `url(/dart2.svg)`, opacity: "0.3"}}> */}
            <div className="form-row-button" >
              
              
              <button className="start-button" >
                <img src={ButtonBackGround} />
              </button>
              
            </div>
        </form>
      </div>
    </div>
  );
}

export default MatchSettingsForm;