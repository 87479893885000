import React, { useContext, useState, useEffect } from 'react'
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import BgImage from "./dartboard.png"
import { useTranslation } from "react-i18next";
import "./UserProfile.css"
/* import { LoggedContext } from '../App' */
let API_URL = process.env.REACT_APP_API_URL

function UserProfil({loggedUserId}) {
    /* const {loggedUserId, setLoggedUserId, loggedUserName, setLoggedUserName} = useContext(LoggedContext) */
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [password_error, setPassword_error] = useState("")
    const [formData, setFormData] = useState({
      id: null,
      firstName: "",
      lastName: "",
      loginName: "",
      email: "",
      password: "",
      confirmPassword: ""
    })

  const get_player_datas = () => { 
    axios({
      method: "GET",
      url: `${API_URL}/registermod/${loggedUserId}/`,
    }).then((response) => {
      const data = response.data
      setFormData({
        ...formData, 
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        loginName: data.login_name,
        email: data.email,
        password: data.password,
        confirmPassword: data.password

      })
    })    
  }

  const handleChange = (event) => {
    setFormData({
        ...formData,
        [event.target.name]: event.target.value
    });
  }

  const handleVerifyClick = async () => {
    setPassword_error("")
    let isLoginFormError = false
    if (!(formData.password === formData.confirmPassword)) {
        isLoginFormError = true
        setPassword_error(t("profile_password_error_label1"))
    }
    else 
        if (formData.password.length <8) {
            isLoginFormError = true
            setPassword_error(t("profile_password_error_label2"))
        }
        else {
            const response = await axios.put(`${API_URL}/registermod/${loggedUserId}/`, {
            id: formData.id,
            first_name: formData.firstName,
            last_name: formData.lastName,
            login_name: formData.loginName,
            email: formData.email,
            password: formData.password,
            })
        }
    if (!isLoginFormError) {
        navigate('/')
    } 
  };

  useEffect(() => {
    if (loggedUserId > 0) {
      get_player_datas();
    } else {
      /* navigate('/') */
    }
    
    } ,[loggedUserId])

  return (
    <>
    {loggedUserId > 0 ? 
      <>
        <div className='main-login'>
          {console.log("Profile - loggeduserId", loggedUserId)}
            <div className='profile_page_login' style={
                {
                /* backgroundImage:'url(/dart2.svg)', */ 
                backgroundImage: `url(${BgImage})`, 
                backgroundRepeat:"no-repeat",
                backgroundSize:"cover",
                backgroundPosition:"center"
              }}>
            </div>
            <div className='profile-page'>
              <div className='profile-form'>
                <div className='profile-name'>
                  <h1>{formData["firstName"] + " " + formData["lastName"]}</h1>
                </div>
                <div className="profile-form-body">



                        <div className="firstname profile-form-row">
                            <label className="form__label" htmlFor="firstName">{t("profile_firstname_label")}</label>
                            <input 
                                type="text" 
                                value={formData.firstName} 
                                name="firstName" 
                                placeholder={t("profile_firstname_label") + "..."}
                                onChange = {handleChange}
                            />
                        </div>
                        <div className="lastname profile-form-row">
                            <label className="form__label" htmlFor="lastName">{t("profile_lastname_label")}</label>
                            <input  
                                type="text" 
                                value={formData.lastName} 
                                name="lastName"  
                                placeholder={t("profile_lastname_label") + "..."}
                                onChange = {handleChange}
                            />
                        </div>
                        <div className="email profile-form-row">
                            <label className="form__label" htmlFor="email">{t("profile_email_label")} </label>
                            <input  
                                type="email" 
                                value={formData.email} 
                                name="email" 
                                placeholder="Email"
                                disabled
                            />
                        </div>
                        <div className="loginname profile-form-row">
                            <label className="form__label" htmlFor="loginName">{t("profile_loginname_label")}</label>
                            <input  
                                type="text" 
                                value={formData.loginName} 
                                name="loginName"  
                                placeholder="Login Name"
                                disabled
                            />
                        </div>
                        <div className="password profile-form-row">
                            <label className="form__label" htmlFor="password">{t("profile_password_label")}</label>
                            <input 
                            type="password" 
                            value={formData.password} 
                            name="password" 
                            placeholder={t("profile_password_label") + "..."}
                            onChange = {handleChange}
                        />
                        </div>
                        <div className="confirm-password profile-form-row">
                            <label className="form__label" htmlFor="confirmPassword">{t("profile_confirm_label")}</label>
                            <input
                                type="password" 
                                value={formData.confirmPassword} 
                                name="confirmPassword" 
                                placeholder={t("profile_confirm_label") + "..."}
                                onChange = {handleChange}
                            />
                        </div>
                    </div>
                    <div className='error-message-container'>
                        {password_error.length? <p>Password: {password_error}</p> : ""}
                    </div>
                <div className="footer-login">
                    <button type="submit" onClick={handleVerifyClick} className="btn">{t("profile_button_label")}</button>
                </div>
            </div>
            </div>

        </div>
      </>
    :
    ""
    }
  </>
  )
}

export default UserProfil
