import { useState } from 'react';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import "./UserRegistration.css"
import BgImage from "./dartboard.png"
import { useTranslation } from "react-i18next";
let API_URL = process.env.REACT_APP_API_URL

const UserRegistration = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [loginName, setLoginName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const uuid = require('uuid');
    const [email_error, setEmail_error] = useState("")
    const [loginName_error, setLoginName_error] = useState("")
    const [password_error, setPassword_error] = useState("")
    const handleInputChange = (e) => {
        const {id , value} = e.target;
        if(id === "firstName"){
            setFirstName(value);
        }
        if(id === "lastName"){
            setLastName(value);
        }
        if(id === "loginName"){
            setLoginName(value);
        }
        if(id === "email"){
            setEmail(value);
        }
        if(id === "password"){
            setPassword(value);
        }
        if(id === "confirmPassword"){
            setConfirmPassword(value);
        }
    }

    const handleVerifyClick = async () => {
        const verificationToken = uuid.v4()
        setEmail_error("")   
        setLoginName_error("")
        setPassword_error("")
        let isLoginFormError = false

        if (!(password === confirmPassword)) {
            isLoginFormError = true
            setPassword_error(t("registration_password_error_label1"))
        }
        else 
            if (password.length <8) {
                isLoginFormError = true
                setPassword_error(t("registration_password_error_label2"))
            }
        if (!isLoginFormError){
            const response = await axios.post(`${API_URL}/register/`, {
            first_name: firstName,
            last_name: lastName,
            login_name: loginName,
            email: email,
            password: password,
            token: verificationToken
            }).catch ((error) => {
            console.error('Failed to send email:', error.response.data);
            if (error.response.data["email"]) {
                setEmail_error(error.response.data["email"][0])
                isLoginFormError = true
            }
            if (error.response.data["login_name"]) {
                setLoginName_error(error.response.data["login_name"][0])
                isLoginFormError = true
            }
            if (error.response.data["password"]) {
                setPassword_error(error.response.data["password"][0])
                isLoginFormError = true
            }
            })
        }
        if (!isLoginFormError) {
            /* sikeres regisztráció oldal */
            navigate('/registering-success/')
        } 
      };

    return (
        <div className='main-registration'>
            <div className='home_page_registration' style={
                {
                /* backgroundImage:'url(/dart2.svg)', */ 
                backgroundImage: `url(${BgImage})`, 
                backgroundRepeat:"no-repeat",
                backgroundSize:"cover",
                backgroundPosition:"center"
                
            }}>
                
            </div>
            <div className='registration-page'>
                <div className='register-form'>
                    <div className="register-form-body">
                        
                        
                        <div className="email register-form-row">
                            <label className="form__label" htmlFor="email">{t("registration_email_label")} </label>
                            <input  
                                type="email" 
                                autoFocus
                                value={email} 
                                id="email" 
                                placeholder={t("registration_email_label") + "..."}
                                onChange = {(e) => handleInputChange(e)}
                            />
                        </div>
                        <div className="loginname register-form-row">
                            <label className="form__label" htmlFor="loginName">{t("registration_loginname_label")}</label>
                            <input  
                                type="text" 
                                name="" 
                                value={loginName} 
                                id="loginName"  
                                placeholder={t("registration_loginname_label") + "..."}
                                onChange = {(e) => handleInputChange(e)}
                            />
                        </div>
                        <div className="password register-form-row">
                            <label className="form__label" htmlFor="password">{t("registration_password_label")}</label>
                            <input 
                            type="password" 
                            value={password} 
                            id="password" 
                            placeholder={t("registration_password_label") + "..."}
                            onChange = {(e) => handleInputChange(e)}
                        />
                        </div>
                        <div className="confirm-password register-form-row">
                            <label className="form__label" htmlFor="confirmPassword">{t("registration_confirm_label")}</label>
                            <input
                                type="password" 
                                value={confirmPassword} 
                                id="confirmPassword" 
                                placeholder={t("registration_confirm_label") + "..."}
                                onChange = {(e) => handleInputChange(e)}
                            />
                        </div>
                        <div className="firstname register-form-row">
                            <label className="form__label" htmlFor="firstName">{t("registration_firstname_label")} </label>
                            <input 
                                /* className="form__input" */ 
                                type="text" 
                                value={firstName} 
                                id="firstName" 
                                placeholder={t("registration_firstname_label") + "..."}
                                onChange = {(e) => handleInputChange(e)}
                            />
                        </div>
                        <div className="lastname register-form-row">
                            <label className="form__label" htmlFor="lastName">{t("registration_lastname_label")}  </label>
                            <input  
                                type="text" 
                                name="" 
                                value={lastName} 
                                id="lastName"  
                                placeholder={t("registration_lastname_label") + "..."}
                                onChange = {(e) => handleInputChange(e)}
                            />
                        </div>
                    </div>
                    <div className='error-message-container'>
                        {email_error.length? <p>E-mail: {email_error}</p> : ""}
                        {loginName_error.length? <p>Login name: {loginName_error}</p> : ""}
                        {password_error.length? <p>Password: {password_error}</p> : ""}
                    </div>
                <div className="footer-login">
                    <button type="submit" onClick={handleVerifyClick} className="btn">{t("registration_button_label")}</button>
                </div>
            </div>
        </div>
    </div>
    )

}

export default UserRegistration