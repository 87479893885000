import { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import "./UserLogin.css"
import BgImage from "./dartboard.png"
import { LoggedContext } from '../App';
import { useTranslation } from "react-i18next";

let API_URL = process.env.REACT_APP_API_URL


const UserLogin = () => {
    const {loggedUserId, setLoggedUserId, loggedUserName, setLoggedUserName} = useContext(LoggedContext)
    const url_players = `${API_URL}/players/`
    const url_players_group = `${API_URL}/players_group/${loggedUserId}/`
    const navigate = useNavigate()
    const { t } = useTranslation();
    const loginTime = new Date()
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [message, setMessage] = useState("")
    const [isValidated, setIsValidated] = useState(true)
    const uuid = require('uuid');

    const handleInputChange = (e) => {
        const {id , value} = e.target;
        if(id === "email"){
            setEmail(value);
        }
        if(id === "password"){
            setPassword(value);
        }
    }

    const handleLoginClick = async () => {
        setMessage("")
        try {
            const response = await axios.get(`${API_URL}/login/${email}/${password}/`);
            if (response.data.id) {
                setMessage("")
                if (response.data.verified) {
                    setLoggedUserId(response.data.id)
                    setLoggedUserName(response.data.login_name)
                    window.localStorage.setItem('loggedUserId', response.data.id)
                    window.localStorage.setItem('loggedUserName', response.data.login_name)
                    window.localStorage.setItem('login_time', loginTime.getTime())
                    // Itt kell ellenőrizni, hogy van-e Player_1, Player_2
                    // Ha a response nem üres, akkor van
                    axios({
                        method: "GET",
                        url: `${API_URL}/players_group/${response.data.id}/`,
                      }).then((res) => {
                        const data = res.data
                        if (data.length) {
                            
                        } else {
                            axios.post(url_players, {        
                                player_name: "Player_1",
                                owner_id: response.data.id
                              })
                            axios.post(url_players, {        
                                player_name: "Player_2",
                                owner_id: response.data.id
                            })
                        }
                    })


                    navigate('/')
                } else {
                    /* setMessage(t("login_notvalidated_error_message")) */
                    setMessage("Email address is not verified!")
                    setIsValidated(false)
                }
            } else {
                setMessage(response.data.message)
                /* setMessage(t("login_invalid_password_message")) */
            }
          } catch (error) {
            setMessage("Invalid email address or login name!")
            /* setMessage(t("login_invalid_login_message")) */
          }
      };

    return (

        <div className='main-login'>
        
            <div className='home_page_login' style={
                {
                /* backgroundImage:'url(/dart2.svg)', */ 
                backgroundImage: `url(${BgImage})`, 
                backgroundRepeat:"no-repeat",
                backgroundSize:"cover",
                backgroundPosition:"center"
                
            }}>
                
            </div>
        <div className='login-page'>
                <div className='my-login-form'>
                    <div className="my-login-form-body">
                        <div className='login-error-message'>
                            {message}
                        </div>
                        <div className="email my-form-row">
                            <label className="login-form-label" htmlFor="email">{t("login_email_label")} </label>
                            <input  
                                type="email" 
                                autoFocus
                                value={email} 
                                id="email" 
                                placeholder={t("login_email_hint")}
                                onChange = {(e) => handleInputChange(e)}
                            />
                        </div>
                        <div className="password my-form-row">
                            <label className="login-form-label" htmlFor="password">{t("login_password_label")}</label>
                            <input 
                            type="password" 
                            value={password} 
                            id="password" 
                            placeholder={t("login_password_hint")}
                            onChange = {(e) => handleInputChange(e)}
                        />
                        </div>
                    </div>
                    <div className="footer-login">
                        <button onClick={handleLoginClick} className="btn-login">{t("login_loginbutton_label")}</button>
                        <Link className="forgotten-password" to="/forgotten/">{t("login_forgetlink_text")}</Link>
                    </div>
                    {!isValidated && <div className='footer-resend'>
                        <p><br />{t("login_notvalidated_message")}</p>
                        {/* <button onClick={handleVerificationResendClick} className="btn">Resend</button> */}
                    </div>}
                    
                </div>
        </div>
    </div>
            
    
    )

}

export default UserLogin