import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useTranslation } from "react-i18next";
import CanvasJSReact from '@canvasjs/react-charts';
import './PlayersResults.css'
import { use } from 'i18next';

function PlayersResults() {
  let API_URL = process.env.REACT_APP_API_URL
  const loggedUserId = window.localStorage.getItem('loggedUserId')
  const { t } = useTranslation();
  const [playerList, setPlayerList] = useState()              // játékosok listája
  const [selectedPlayer, setSelectedPlayer] = useState(0)     // kiválasztott játékos id-a
  const [matchesOfSelectedPlayer, setMatchesOfSelectedPlayer] = useState(null) // A játékos által játszott meccsek id-i
  const [allShootsOfMatches, setAllShootsOfMatches] = useState()
  const [sumPlayedMatches, setSumPlayedMatches] = useState(0)  // összesen mennyi meccsett játszott
  const [legsOfSelectedPlayer, setLegsOfSelectedPlayer] = useState(0)    // A játékos által játszott leg-ek id-i
  const [sumPlayedLegs, setSumPlayedLegs] = useState(0)     // összesen mennyi leg-et játszott
  const [wonlegs, setWonLegs] = useState(0)                 //  összesen mennyi leg-et nyert
  const [wonMatch, setWonMatch] = useState(0)
  const [playerShoots, setPlayerShoots] = useState()        // a játokos összes dobása
  const [sumPlayerShootsCount, setSumPlayerShootsCount] = useState(0)
  const [sumPointsPlayer1, setSumPointsPlayer1] = useState(null)
  const [sumDartCountPlayer1, setSumDartCountPlayer1] = useState(null)
  const [sumFirst9PointsPlayer1, setSumFirst9PointsPlayer1] = useState(null)
  const [sumFirst9DartCount, setSumFirst9DartCount] = useState(null)
  const [shotsRange1, setShotsRange1] = useState(null)
  const [playerMatchSettings, setPlayerMatchSettings] = useState(null) // a játékos meccseinek beállításai
  const [playerMaxCheckout, setPlayerMaxCheckout] = useState(0)
  const [player100PlusCount, setPlayer100PlusCount] = useState(0)
  const [chartOptions, setChartOptions] = useState()
  var CanvasJS = CanvasJSReact.CanvasJS;
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;

  

  useEffect(() => {
    /* getOwnerMatches() */   // a tulaj által indított összes meccs
    getOwnerPlayers()   // a tulaj által regisztrált összes játékos
  }, [])

  useEffect(() => {
    if (selectedPlayer > 0) {
      getMatchesOfPlayer(selectedPlayer)
      getPlayerShoots()
    }
  }, [selectedPlayer])

  useEffect(() => {
    if (matchesOfSelectedPlayer) {
      getLegsOfPlayer(matchesOfSelectedPlayer)
      getMatchSettingsOfPlayer(matchesOfSelectedPlayer)
      getAllShootsOfMatches(matchesOfSelectedPlayer)
      /* computeResult(legsOfSelectedPlayer, playerMatchSettings) */
    }
  }, [matchesOfSelectedPlayer])

  useEffect(() => {
    if (legsOfSelectedPlayer) {
      getWonLegsOfPlayer()
    }
  }, [legsOfSelectedPlayer])

  useEffect(() => {
    if (playerShoots) {
      computeAverages(playerShoots)
      computeShotsRange(playerShoots)
    }
  }, [playerShoots])

  useEffect(() => {
    computeResult(legsOfSelectedPlayer, playerMatchSettings)
  }, [legsOfSelectedPlayer ,playerMatchSettings])

  useEffect(() => {
    allShootsOfMatches && computeMaxCheckout(allShootsOfMatches)
  }, [allShootsOfMatches])

  useEffect(() => {
    if (shotsRange1) {
      const dataArray = []
      const labelArray = ["180", "40-", "40+", "60+", "80+", "100+", "120+", "140+", "180"]
      Object.values(shotsRange1).map(item => dataArray.push(item))      
      const tempDataPoints = []
      let data180 = 0

      dataArray.map((value, index) => {
        if (index === 0) {
          data180 = value          
        } else if (index >=2 ){
          tempDataPoints.push({label: labelArray[index - 1], y: value})
        }
      })
      tempDataPoints.push({label: "180", y: data180})
      const options = {
        title: {
          text: "Dobások eloszlása"
        },
        data: [{				
          type: "column",
          dataPoints: tempDataPoints
          /* dataPoints: [
            { label: "40-",  y: `{shotsRange1["range2"]}`  },
            { label: "40+", y: 15  },
            { label: "60+", y: 15  },
            { label: "80+", y: 25  },
            { label: "100+",  y: 30  },
            { label: "120+",  y: 28  },
            { label: "140+",  y: 28  },
            { label: "180",  y: 28  }
          ] */
        }]
      }
      setChartOptions(options)
    }
  }, [shotsRange1])

  function computeMaxCheckout(data) {
    let maxChek = 0
    let count100plus = 0
    let bestLeg = 0
    let worstLeg = 0
    // Sorok rendezése timestamp alapján csökkenő sorrendbe
    const sortedData = data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    // Az utolsó timestamp-eket tartalmazó sorok megtartása minden egyes kombinációhoz
    const uniqueRows = [];
    const uniqueCombinations = new Set();

    sortedData.forEach(row => {
      const combinationKey = `${row.match_id}_${row.set_id}_${row.leg_id}`;
      if (!uniqueCombinations.has(combinationKey)) {
        uniqueCombinations.add(combinationKey);
        uniqueRows.push(row);
      }
    });

    /* console.log(uniqueRows); */
    uniqueRows.forEach(row => {
      if (Number(selectedPlayer) === row.player_id) {
        if (row.points > maxChek) {
          maxChek = row.points
        }
        if (row.points >= 100) {
          count100plus += 1
        }
        if (worstLeg < row.round_number) {
          worstLeg = row.round_number
        }  
        if (bestLeg === 0) {
          bestLeg = row.round_number
        }
        if (bestLeg > row.round_number) {
          bestLeg = row.round_number
        }
      }
    })
    setPlayerMaxCheckout(maxChek)
    setPlayer100PlusCount(count100plus)
    /* console.log("max kiszálló: ", maxChek)
    console.log("100+ kiszálló: ", count100plus)
    console.log("Best Leg: ", bestLeg)
    console.log("Worstleg: ", worstLeg) */
  }

  function computeResult(match_detail, match_settings) {    // legsOfSelectedPlayer ,playerMatchSettings
    let aktPlayer = null
    let otherPlayer = null
    let winnedMatch = 0

    match_detail && match_settings && match_settings.forEach((match) => { 
      if (match.player1_id === Number(selectedPlayer)) {
        aktPlayer = match.player1_id
        otherPlayer = match.player2_id
      } else {
        aktPlayer = match.player2_id
          otherPlayer = match.player1_id
      }

      const osszesites = {};
      match_detail && match_detail.forEach((eredmeny) => {
        if (eredmeny.match_id === match.id) {
          /* legs_per_meccs += 1 */
          const { set_id, winner_id, match_id} = eredmeny;
          const pair = `${match_id}-${set_id}`;   // a meccsenkénti és set-enkénti összegzéshez kulcs
          // aktuális pair hozzáadása az összesítéshez, ha még nincs
          if (!osszesites[pair]) {
            osszesites[pair] = { nyertesek: {} };
          }
          // Győztes hozzáadása az adott pair-hez
          if (!osszesites[pair].nyertesek[winner_id]) {
            osszesites[pair].nyertesek[winner_id] = 1;
          } else {
            osszesites[pair].nyertesek[winner_id]++;
          }
          // Ha valaki nem nyert az adott match-setben, akkor 0-hoz adja hozzá
          if (!osszesites[pair].nyertesek[aktPlayer]) {
              osszesites[pair].nyertesek[aktPlayer] = 0;
          }
          if (!osszesites[pair].nyertesek[otherPlayer]) {
            osszesites[pair].nyertesek[otherPlayer] = 0;
          }
        }
      });

      let aktWon = 0; let otherWon = 0
      Object.keys(osszesites).forEach((key) => {
        const item = osszesites[key]
        if (item.nyertesek[aktPlayer] > item.nyertesek[otherPlayer]) {
          aktWon += 1
        } else {
          otherWon += 1
        }
      })
      if (aktWon > otherWon) {
        winnedMatch += 1
      }
    })
    setWonMatch(winnedMatch)
    /* console.log("győztes meccsek száma: ", winnedMatch) */
}

  const getAllShootsOfMatches = async () => {
    await axios({
      method: "GET",
      url: `${API_URL}/shootsbymatches/${matchesOfSelectedPlayer}/`,
    }).then((response) => {
        const tempData = response.data
        setAllShootsOfMatches(tempData)
    })
  }

  function getOwnerPlayers () {
    axios({
        method: "GET",
        url: `${API_URL}/players_group/${loggedUserId}/`,
      }).then((response) => {
        const data = response.data  // itt van az összes tulaj által regisztrált játékos
        setPlayerList(data);
    })
  }

  const getMatchesOfPlayer = async (playerId) => {
    await axios({
        method: "GET",
        url: `${API_URL}/playermatches/${playerId}/`,
    }).then((response) => {
        const tempData = response.data
        const listOfMatches = []
        tempData.map((item) => {listOfMatches.push(item['id'])})
        setMatchesOfSelectedPlayer(listOfMatches)
        setSumPlayedMatches(Object.keys(tempData).length)
    })
  }

  const getLegsOfPlayer = async () => {
    await axios({
        method: "GET",
        url: `${API_URL}/legsbymatches/${matchesOfSelectedPlayer}/`,
    }).then((response) => {
        const tempData = response.data
        /* console.log("legek listája:(legsOfSelectedPlayer)", tempData) */
        setLegsOfSelectedPlayer(tempData)
        setSumPlayedLegs(Object.keys(tempData).length)
    })
  }

  const getMatchSettingsOfPlayer = async () => {
    await axios({
        method: "GET",
        url: `${API_URL}/settingsbymatches/${matchesOfSelectedPlayer}/`,
    }).then((response) => {
        const tempData = response.data
        /* console.log("meccsek beállításai:(playerMatchSettings)", tempData) */
        setPlayerMatchSettings(tempData)
        /* setSumPlayedLegs(Object.keys(tempData).length) */
    })
  }

  const getWonLegsOfPlayer = async () => {
    await axios({
        method: "GET",
        url: `${API_URL}/wonlegbyplayer/${matchesOfSelectedPlayer}/${selectedPlayer}/`,
    }).then((response) => {
        const tempData = response.data
        /* console.log("győztes legek száma:", tempData) */
        setWonLegs(tempData['wonnedlegs'])
    })
  }

  const getPlayerShoots = async () => {
    await axios({
        method: "GET",
        url: `${API_URL}/player_shoots/${selectedPlayer}/`,
    }).then((response) => {
        const tempData = response.data
        /* console.log("Játékos dobásai:", tempData)
        console.log("összes dobás száma: ", Object.keys(tempData).length) */
        setSumPlayerShootsCount(Object.keys(tempData).length)
        setPlayerShoots(tempData)
    })
  }

  function computeShotsRange(data ) {
    const pointRanges = [180, 0, 40, 60, 80, 100, 120, 140, 180];
    
    const countPointsByRange = (playerId) => {
        const pointsCount = {};

        pointRanges.forEach((range, index) => {
            pointsCount[`range${index}`] = 0;
        });

        data.forEach((row) => {
            pointRanges.forEach((range, index) => {
                if (index <= 1 && row.points === range) { pointsCount[`range${index}`]++; }
                if  (index > 1 && (row.points >= pointRanges[index -1] && row.points < range))
                { pointsCount[`range${index}`]++; }
            });
        });
        return pointsCount;
    };

    const player1PointsCount = countPointsByRange(selectedPlayer);
    setShotsRange1(player1PointsCount)
    /* console.log("p1 shot range: ", player1PointsCount) */
}

  function computeAverages(data) {
    // Összegzi a player_id alapján a points értékeit
    const sumPointsByPlayer = data.reduce((acc, row) => {
        const { player_id, points } = row;
        acc[player_id] = (acc[player_id] || 0) + points;
        return acc;
    }, {});
    setSumPointsPlayer1(sumPointsByPlayer[selectedPlayer])
    /* console.log("Összegzett points player_id alapján (sumPointsByPlayer):", sumPointsByPlayer); */

    const sum9PointsByPlayer = data.reduce((result, row) => {
        const { player_id, round_number, points } = row;
        if (round_number <= 9) {
          if (!result[player_id]) {
            result[player_id] = 0;
          }
          result[player_id] += points;
        }
        return result;
      }, {});
      setSumFirst9PointsPlayer1(sum9PointsByPlayer[selectedPlayer])
      /* console.log("9 nyilas összeg (sum9PointsByPlayer):", sum9PointsByPlayer); */

    const uniquePairs = new Set();
    data.forEach(row => {
        const { set_id, leg_id, match_id } = row;
        const pair = `${set_id}-${leg_id}-${match_id}`;
        uniquePairs.add(pair);
      });
    const totalFirst9DartCount = uniquePairs.size * 3;
    setSumFirst9DartCount(totalFirst9DartCount)
    /* console.log("Első 9 nyíl darabszám (totalFirst9DartCount):",totalFirst9DartCount); */
      

    const sumMaxRoundNumberByPlayer = {};
    data.forEach((row) => {
        const { player_id, set_id, leg_id, round_number, match_id } = row;
        const key = `${player_id}-${set_id}-${leg_id}-${match_id}`;
        if (!sumMaxRoundNumberByPlayer[player_id]) {
            sumMaxRoundNumberByPlayer[player_id] = 0;
        }
        if (!sumMaxRoundNumberByPlayer[key] || sumMaxRoundNumberByPlayer[key] < round_number) {
            sumMaxRoundNumberByPlayer[key] = round_number;
        }
    });

    // Összegzés a player_id alapján
    const sumByPlayer = {};
    Object.keys(sumMaxRoundNumberByPlayer).forEach((key) => {
        const playerId = key.split('-')[0];
        if (!sumByPlayer[playerId]) {
            sumByPlayer[playerId] = 0;
        }
        sumByPlayer[playerId] += sumMaxRoundNumberByPlayer[key];
    });
    setSumDartCountPlayer1(sumByPlayer[selectedPlayer])
    /* console.log("Összegzett max round_number player_id alapján (sumByPlayer):", sumByPlayer); */
}

  const handleSelectOnchange = (e) => {
    setSelectedPlayer(e.target.value)
  }

  return (
    <div className='player-results-screen'>
        <div className='player-results-title'>
          <div>{t("player_results")}</div>
        
        </div>
        
        <div className='player-result-container'>
            {/* <div className='player-container'> */}
                <div className='player-list-container'>
                    {/* <div><b>Játékos:</b></div> */}
                    <div  className='player-select-container'>
                        <select className='player-select'
                            value={selectedPlayer}
                            onChange={(e) => handleSelectOnchange(e)}
                        >
                            <option value={0}>Kérem válasszon....</option>

                            {playerList ? (playerList.map((player) => (
                                <option 
                                    key={player.id} 
                                    value={player.id}
                                >
                                    {player.player_name} 
                                </option>
                            ))) : ""}
                            
                        </select>
                    </div>
                </div>
                {/* ezt csak akkor, ha van már játszott meccs */}
                <div className='player-stat-container'>
                    <div className='player-performance-container'>
                      <div>
                        <div className='p-stats-row'><div>Összesen mérkőzés:</div>{sumPlayedMatches ? (<><div> {sumPlayedMatches}</div></>) : <div>0</div>}</div>
                        <div className='p-stats-row'><div>Nyertes mérkőzés:</div>{wonMatch ? (<><div> {wonMatch}</div></>) : <div>0</div>}</div>
                        <div className='p-stats-row'><div>Vesztes mérkőzés:</div>{sumPlayedMatches && (wonMatch || 1) ? (<><div> {(Number(sumPlayedMatches) - Number(wonMatch) )}</div></>) : <div>0</div>}</div>
                        <div className='p-stats-row'><div>Gyöztes/összes aránya:</div>{sumPlayedMatches && wonMatch ? (<><div> {(Number(wonMatch) / Number(sumPlayedMatches) * 100).toFixed(2)}%</div></>) : <div>0</div>}</div>
                        <hr></hr>
                        <div className='p-stats-row'><div>Összesen leg:</div>{sumPlayedLegs ? (<><div> {sumPlayedLegs}</div></>) : <div>0</div>}</div>
                        <div className='p-stats-row'><div>Nyertes leg:</div>{wonlegs ? (<><div> {wonlegs}</div></>) : <div>0</div>}</div>
                        <div className='p-stats-row'><div>Vesztes leg:</div>{sumPlayedLegs && (wonlegs || 1) ? (<><div> {(Number(sumPlayedLegs) - Number(wonlegs))}</div></>) : <div>0</div>}</div>
                        <div className='p-stats-row'><div>Legarány:</div>{sumPlayedLegs && (wonlegs || 1) ? (<><div> {wonlegs - (Number(sumPlayedLegs) - Number(wonlegs))}</div></>) : <div>0</div>}</div>
                        <div className='p-stats-row'><div>Gyöztes/összes aránya:</div>{sumPlayedLegs && wonlegs ? (<><div> {(Number(wonlegs) / Number(sumPlayedLegs) * 100).toFixed(2)}%</div></>) : <div>0</div>}</div>                      
                        <hr></hr>
                        <div className='p-stats-row'><div>Legnagyobb kiszálló:</div>{playerMaxCheckout ? (<><div> {playerMaxCheckout}</div></>) : <div>0</div>}</div>
                        <div className='p-stats-row'><div>100+-os kiszállók:</div>{player100PlusCount ? (<><div> {player100PlusCount}</div></>) : <div>0</div>}</div>
                      </div>
                    </div>
                    
                    <div className='player-shotstat-container'>
                      <div>
                        {sumPointsPlayer1 && sumDartCountPlayer1 && sumFirst9PointsPlayer1 && sumFirst9DartCount ? 
                        (<>
                          <div className='p-stats-row'>
                              <div>3 nyilas átlag:</div><div> {(Number(sumPointsPlayer1) / Number(sumDartCountPlayer1) * 3).toFixed(2)}</div>
                          </div>  
                          <div className='p-stats-row'>
                              <div>Első 9 nyílas átlag:</div><div> {(Number(sumFirst9PointsPlayer1) / Number(sumFirst9DartCount)).toFixed(2)}</div>
                          </div>
                        </>) : 
                        (<>
                          <div className='p-stats-row'>
                              <div>3 nyilas átlag:</div><div>0</div>
                          </div>  
                          <div className='p-stats-row'>
                              <div>Első 9 nyílas átlag:</div><div>0</div>
                          </div>
                        </>)}
                        <hr></hr>
                        <div >
                          {shotsRange1 && sumPlayerShootsCount ? (<>
                            <div className='p-stats-row'><div>Összes dobás száma:</div><div>{sumPlayerShootsCount}</div></div>
                            <hr></hr>
                            <div className='p-range-row'><div>40-</div><div> {shotsRange1["range2"]}</div><div> {(Number(shotsRange1["range2"]) / Number(sumPlayerShootsCount) * 100).toFixed(2)}%</div></div>
                            <div className='p-range-row'><div>40+</div><div> {shotsRange1["range3"]}</div><div> {(Number(shotsRange1["range3"]) / Number(sumPlayerShootsCount) * 100).toFixed(2)}%</div></div>
                            <div className='p-range-row'><div>60+ </div><div>{shotsRange1["range4"]}</div><div> {(Number(shotsRange1["range4"]) / Number(sumPlayerShootsCount) * 100).toFixed(2)}%</div></div>
                            <div className='p-range-row'><div>80+ </div><div>{shotsRange1["range5"]}</div><div> {(Number(shotsRange1["range5"]) / Number(sumPlayerShootsCount) * 100).toFixed(2)}%</div></div>
                            <div className='p-range-row'><div>100+</div><div> {shotsRange1["range6"]}</div><div> {(Number(shotsRange1["range6"]) / Number(sumPlayerShootsCount) * 100).toFixed(2)}%</div></div>
                            <div className='p-range-row'><div>120+ </div><div>{shotsRange1["range7"]}</div><div> {(Number(shotsRange1["range7"]) / Number(sumPlayerShootsCount) * 100).toFixed(2)}%</div></div>
                            <div className='p-range-row'><div>140+</div><div> {shotsRange1["range8"]}</div><div> {(Number(shotsRange1["range8"]) / Number(sumPlayerShootsCount) * 100).toFixed(2)}%</div></div>
                            <div className='p-range-row'><div>180 </div><div>{shotsRange1["range0"]}</div><div> {(Number(shotsRange1["range0"]) / Number(sumPlayerShootsCount) * 100).toFixed(2)}%</div></div>
                          </>) : 
                          (<>
                            <div className='p-stats-row'><div>Összes dobás száma:</div><div>0</div></div>
                            <hr></hr>
                            <div className='p-range-row'><div>40-</div><div>0</div></div>
                            <div className='p-range-row'><div>40+</div><div>0</div></div>
                            <div className='p-range-row'><div>60+ </div><div>0</div></div>
                            <div className='p-range-row'><div>80+ </div><div>0</div></div>
                            <div className='p-range-row'><div>100+</div><div>0</div></div>
                            <div className='p-range-row'><div>120+ </div><div>0</div></div>
                            <div className='p-range-row'><div>140+</div><div>0</div></div>
                            <div className='p-range-row'><div>180 </div><div>0</div></div>
                          </>)}
                        </div>
                        <div>
                          {chartOptions && <CanvasJSChart options = {chartOptions}
                          //onRef = {ref => this.chart = ref}
                        />}
                        </div>
                      </div>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default PlayersResults
